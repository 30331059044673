import React, { memo, useEffect, useRef, useState } from "react";
import { ConceptType, GlobalNotifications } from "../../models/common/CommonEnums";
import { ConceptLinkSummaryLoadActionResponse } from "../../models/communication/actions/ConceptLinkSummaryLoadAction";
import { ConceptTagLoadActionResponse } from "../../models/communication/actions/ConceptTagLoadAction";
import { TagDeleteActionResponse } from "../../models/communication/actions/TagDeleteAction";
import { TagUpdateActionResponse } from "../../models/communication/actions/TagUpdateAction";
import { ConceptViewerNotificationModel } from "../../models/communication/internal/ConceptViewerNotificationModel";
import { ConceptDataModel, ConceptLinksSummaryModel } from "../../models/ConceptDataModel";
import { MessageType } from "../../models/message/IServerMessage";
import { ConceptService } from "../../services/ConceptService";
import { GlobalNotification, GlobalNotificationHub } from "../../services/GlobalMessageHub";
import { HelperExtension } from "../../services/HelperExtension";
import { MessageHubOwner } from "../../services/messaging/MessageHubOwner";
import { IHubSubcription, MessageHubContext } from "../../services/notification/MessageHubHandler";
import { ConceptLinkSummaryStore } from "../../services/stores/ConceptLink/ConceptLinkSummaryStore";
import { GlobalStore } from "../../services/stores/GlobalStore";
import { ConceptTagStore } from "../../services/stores/Tag/ConceptTagStore";
import { ImplementationLevelComponent } from "../elements/ImplementationLevelComponent";
import { TagComponent } from "../tags/TagComponent";
import { CommonElements } from "../../models/CommonElements";
import { LogCollector } from "../../services/logger/LogCollector";

interface CardDisplayComponentProps {
    conceptModel: ConceptDataModel;

    disableConceptLink?: boolean;
    onUnlinkClick?: () => void;
    disableDefaultAction?: boolean;
}

const CardDisplayComponent: React.FunctionComponent<CardDisplayComponentProps>
    = memo(
        function CardDisplay({ conceptModel, disableConceptLink, onUnlinkClick, disableDefaultAction }) {

            //const [conceptTagStore] = useState(new ConceptTagStore({ conceptID: conceptModel.conceptID } as ConceptTagStoreProps));
            const [conceptTagStore] = useState(new ConceptTagStore());
            const [conceptLinkSummaryStore] = useState(new ConceptLinkSummaryStore());
            const [savingPending] = useState(conceptModel.savePending);

            const cardRef = useRef<HTMLDivElement>(null);

            // const [{ isDragging }, dragRef, preview] = useDrag(
            //     () => ({
            //         type: "concept.card",
            //         item: { x: "x" },
            //         collect: (monitor: DragSourceMonitor) => ({
            //             isDragging: monitor.isDragging(),
            //         })
            //     }),
            //     []
            // )

            // useEffect(() => {
            //     preview(getEmptyImage(), { captureDraggingState: true })
            // }, [])


            useEffect(() => {

                const onConceptUpdate = (conceptUpdated: ConceptDataModel) => {
                    if (conceptUpdated.conceptID === conceptModel.conceptID) {
                        conceptModel.savePending = false;
                        //setSavingPending(false);
                        if (cardRef?.current) {
                            cardRef.current.classList.remove("concept-saving");
                        }
                        LogCollector.LogMessage("OnConceptUpdate");
                    }
                }

                const onConceptPendingSave = (notification: GlobalNotification) => {
                    if (conceptModel.conceptID === notification.notificationData.conceptID) {
                        conceptModel.savePending = true;
                        //setSavingPending(true);
                        if (cardRef?.current) {
                            cardRef.current.classList.add("concept-saving");
                        }
                        LogCollector.LogMessage("onConceptPendingSave");
                    }
                }

                const onConceptLinkUpdate = (conceptLinkUpdated: ConceptLinkSummaryLoadActionResponse) => {
                    conceptLinkSummaryStore.updateData(conceptLinkUpdated);

                    if (conceptLinkUpdated.conceptID === conceptModel.conceptID) {
                        ConceptService.processPartialUpdate({
                            conceptID: conceptModel.conceptID,
                            conceptType: conceptModel.conceptType,

                            conceptLinkSummary: conceptLinkUpdated.conceptLinkSummary
                        } as ConceptDataModel, false);
                        //conceptModel.conceptLinkSummary = conceptLinkUpdated.conceptLinkSummary;
                    }
                }

                const OnConceptTagServerUpdate = (conceptTagUpdated: ConceptTagLoadActionResponse) => {

                    if (conceptTagUpdated.conceptID === conceptModel.conceptID) {
                        //conceptTagStore.updateData(conceptTagUpdated.tagList);
                        conceptModel.tagList = conceptTagUpdated.tagList;
                        conceptTagStore.updateData(conceptTagUpdated.tagList);
                        LogCollector.LogMessage("OnConceptTagServerUpdate");
                    }
                }

                const OnTagRenamed = (tagRenameResponse: TagUpdateActionResponse) => {
                    if (tagRenameResponse?.tag) {
                        conceptTagStore.processRename(tagRenameResponse.tag);
                    }
                }

                const OnTagDelete = (tagDeleteResponse: TagDeleteActionResponse) => {
                    if (tagDeleteResponse?.deletedTag) {
                        conceptTagStore.processDelete(tagDeleteResponse.deletedTag);
                    }
                }

                const messageHubContext = MessageHubContext()
                    .ListenGlobalNotification([GlobalNotifications.ConceptSavePending], onConceptPendingSave)
                    .ListenMessage(MessageHubOwner, { MessageType: MessageType.ConceptCreated, OnReceive: onConceptUpdate } as IHubSubcription)
                    .ListenMessage(MessageHubOwner, { MessageType: MessageType.ConceptLinkSummaryLoaded, OnReceive: onConceptLinkUpdate } as IHubSubcription)
                    .ListenMessage(MessageHubOwner, { MessageType: MessageType.ConceptTagLoaded, OnReceive: OnConceptTagServerUpdate } as IHubSubcription)
                    .ListenMessage(MessageHubOwner, { MessageType: MessageType.TagRenamed, OnReceive: OnTagRenamed } as IHubSubcription)
                    .ListenMessage(MessageHubOwner, { MessageType: MessageType.TagDeleted, OnReceive: OnTagDelete } as IHubSubcription);

                //conceptTagStore.initialize(conceptModel.conceptType, conceptModel.tagList);
                conceptTagStore.initialize(conceptModel);

                conceptLinkSummaryStore.initialize(conceptModel.conceptID, conceptModel.conceptLinkSummary);

                return () => messageHubContext.Dispose();
            }, [conceptModel, conceptTagStore, conceptLinkSummaryStore]);

            const OnConceptLink = () => {
                GlobalNotificationHub.sendMessageWithData(GlobalNotifications.ViewConcept, { conceptID: conceptModel.conceptID } as ConceptViewerNotificationModel);
            }

            const OnConceptLinkClickNewTab = () => {
                switch (conceptModel.conceptType) {
                    case ConceptType.Insights:
                        window.open("/w/" + GlobalStore.getWorkspaceCodeIfAvailable() + "/insights/" + conceptModel.conceptID + "/", "_blank");
                        break;
                    case ConceptType.Problems:
                        window.open("/w/" + GlobalStore.getWorkspaceCodeIfAvailable() + "/problems/" + conceptModel.conceptID + "/", "_blank");
                        break;
                    case ConceptType.Solutions:
                        window.open("/w/" + GlobalStore.getWorkspaceCodeIfAvailable() + "/solutions/" + conceptModel.conceptID + "/", "_blank");
                        break;
                }
            }

            LogCollector.LogMessage("CardDisplay:" + conceptModel.conceptID);

            const RenderTitle = () => {

                return (
                    disableConceptLink
                        ? <div role="button" className="fw-dark" onClick={OnConceptLinkClickNewTab}>{conceptModel.title}</div>
                        : <div role="button" className="fw-dark" onClick={OnConceptLink} data-bs-toggle="modal" data-bs-target={CommonElements.ModalElementTarget}>{conceptModel.title}</div>
                )
            }

            const RenderBody = () => {

                if (conceptModel.conceptType === ConceptType.Problems) {

                    const problemDefinition = HelperExtension.GetProblemDefinition(conceptModel);

                    return (
                        <React.Fragment>
                            <ul className="m-0 pt-1">
                                {
                                    (problemDefinition.g) &&
                                    <li className="fw-small-400 fw-dark"><span className="fw-small-600 me-1">Goal:</span>{problemDefinition.g}</li>
                                }
                                {
                                    (problemDefinition.v) &&
                                    <li className="fw-small-400 fw-dark"><span className="fw-small-600 me-1">Value:</span>{problemDefinition.v}</li>
                                }
                            </ul>
                            {
                                (!disableDefaultAction) &&
                                <div className={"vc-actions d-flex"}>
                                    <TagComponent conceptTagStore={conceptTagStore} hideIcon={true} />
                                    <RenderConceptLinkSummary conceptLinkSummaryStore={conceptLinkSummaryStore} />
                                </div>
                            }
                            {
                                (onUnlinkClick) &&
                                <div className="pt-3">
                                    <button type="button" className="btn btn-light btn-concept-light btn-xsmall text-nowrap" style={{ width: "80px" }} onClick={onUnlinkClick}>
                                        <svg className="pe-none" width="18" height="15" role="img" aria-label="Link"><use xlinkHref="#unlink-icon"></use></svg>
                                        <span className="ps-1">Unlink</span>
                                    </button>
                                </div>
                            }
                        </React.Fragment>
                    )
                }


                return (
                    <div className={"vc-actions d-flex pt-3"}>
                        <TagComponent conceptTagStore={conceptTagStore} hideIcon={true} />
                        <RenderConceptLinkSummary conceptLinkSummaryStore={conceptLinkSummaryStore} />
                    </div>
                );
            }


            return (
                <div ref={cardRef}
                    className={"voyce-card voyce-card-default disable-selection-events d-flex shadow-sm" + (savingPending ? " concept-saving" : "")}>
                    <div className="voyce-card-mobile-handle white-icon">
                        <svg className="pe-none" width="12" height="15" role="img" aria-label="Drag"><use xlinkHref="#three-lines-icon"></use></svg>
                    </div>
                    <div className="w-100 p-3">
                        <div className="d-flex justify-content-between align-items-start">
                            <div className="fw-med-600 text-break">
                                <RenderTitle />
                            </div>
                            {
                                (conceptModel.conceptType !== ConceptType.Insights) &&
                                <ImplementationLevelComponent currentLevel={conceptModel.conceptValue} conceptType={conceptModel.conceptType} />
                            }
                        </div>
                        <RenderBody />
                    </div>
                </div >
            );
        }
    );

interface RenderConceptLinkSummaryProps {
    conceptLinkSummaryStore: ConceptLinkSummaryStore;
}
const RenderConceptLinkSummary = (props: RenderConceptLinkSummaryProps) => {

    const [linkSummary, setLinkSummary] = useState<ConceptLinksSummaryModel>();


    useEffect(() => {

        const hubContext = MessageHubContext()
            .Subscribe(props.conceptLinkSummaryStore, (summary: ConceptLinksSummaryModel) => {

                setLinkSummary({
                    ...summary
                });

            });


        return () => hubContext.Dispose();

    }, [props.conceptLinkSummaryStore])

    if (!linkSummary) {
        return (<React.Fragment></React.Fragment>);
    }

    return (
        <div className="d-flex align-items-center ms-auto">
            {
                (linkSummary.insightsCount > 0) &&
                <div data-theme="insight">
                    <div className="btn-concept-display btn-xsmall">
                        <svg className="p-none" width={14} height={15} role="img"><use xlinkHref={HelperExtension.GetConceptIcon(ConceptType.Insights)}></use></svg>
                        <div className="ms-2">
                            {linkSummary.insightsCount}
                        </div>
                    </div>
                </div>
            }
            {
                (linkSummary.problemsCount > 0) &&
                <div data-theme="problem">
                    <div className="btn-concept-display btn-xsmall">
                        <svg className="p-none" width={15} height={15} role="img"><use xlinkHref={HelperExtension.GetConceptIcon(ConceptType.Problems)}></use></svg>
                        <div className="ms-2">
                            {linkSummary.problemsCount}
                        </div>
                    </div>
                </div>
            }
            {
                (linkSummary.solutionsCount > 0) &&
                <div data-theme="solution">
                    <div className="btn-concept-display">
                        <svg className="p-none" width={18} height={15} role="img"><use xlinkHref={HelperExtension.GetConceptIcon(ConceptType.Solutions)}></use></svg>
                        <div className="ms-2">
                            {linkSummary.solutionsCount}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default CardDisplayComponent;