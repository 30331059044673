import React from "react";
import { ConceptType, GlobalNotifications } from "../../models/common/CommonEnums";
import { ConceptSearchService } from "../../services/concepts/ConceptSearchService";
import { ConceptLinkStore } from "../../services/stores/ConceptLink/ConceptLinkStore";
import { GlobalNotificationHub } from "../../services/GlobalMessageHub";
import { ConceptLinkExistingNotificationModel } from "../../models/communication/internal/ConceptLinkExistingNotificationModel";

interface ConceptLinkComponentProps {
    conceptLinkStore: ConceptLinkStore;
    conceptType: ConceptType;

    buttonText?: string;
    buttonIcon?: string;
    buttonStyle?: string;
}


export const ConceptLinkComponent = (props: ConceptLinkComponentProps) => {

    const onChangeLink = async () => {
        await ConceptSearchService.searchConcept(props.conceptType, "", "");
        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.LinkConcept, { targetConceptType: props.conceptType, conceptLinkStore: props.conceptLinkStore } as ConceptLinkExistingNotificationModel);
    }

    const GetButtonStyle = () => {
        return props.buttonStyle ?? "btn-light btn-concept-lighter btn-xsmall";
    }

    const GetButtonContent = () => {

        if (props.buttonIcon) {

            return (
                <React.Fragment>
                    <svg className="pe-none me-1" width="16" height="16" role="img" aria-label="Home"><use xlinkHref={props.buttonIcon}></use></svg>
                    {props.buttonText ?? "+ Link"}
                </React.Fragment>
            )
        }

        return <React.Fragment>{props.buttonText ?? "+ Link"}</React.Fragment>
    }

    const dropdownUID = Math.random().toString(36);

    return (
        <div className="concept-link-section btn-group dropstart">
            <button id={dropdownUID} type="button" className={"btn text-nowrap " + GetButtonStyle()}
                // data-bs-toggle="dropdown"
                // data-bs-auto-close="outside"
                // aria-expanded="false"
                onClick={onChangeLink}>

                <GetButtonContent />
            </button>
            {/* <ConceptLinkContentComponent dropdownAligment="" dropdownUID={dropdownUID} {...props} /> */}
            {/* <div className="dropdown-menu shadow drop-container drop-container-link">
                <div className="bg-header text-white p-3 ps-4 rounded-top d-flex align-items-center">
                    <div>
                        <SectionHeaderComponent iconName="#link-icon"
                            header={"Link a " + HelperExtension.GetConceptName(props.conceptType, 1).toLowerCase() + " to this " + context.conceptTypeDescription.toLowerCase()}
                            iconSize={SectionHeaderIconSize.Medium} headerStyle="icon-bright" />
                    </div>
                    <div className="m-auto"></div>
                    <div><button type="button" className="btn-close btn-close-white" onClick={() => { global.window.closeCustomDropdown(dropdownUID); }}></button></div>
                </div>
                <div className="vc-editor vc-list-group" >
                    {
                        <ConceptSearchComponent conceptLinkStore={props.conceptLinkStore} conceptType={props.conceptType} />
                    }
                </div>
                <div className="m-3 mt-2">
                    <button className="btn btn-primary btn-concept w-100" onClick={() => { global.window.closeCustomDropdown(dropdownUID); }}>Done</button>
                </div>
            </div> */}
        </div>
    );
}