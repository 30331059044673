import { useCallback, useEffect, useState } from "react";
import { GlobalNotifications, ConceptType, ConceptTypeEnumLabel, PageType } from "../models/common/CommonEnums";
import { FolderDataModel } from "../models/data/FolderDataModel";
import { HelperExtension } from "../services/HelperExtension";
import { GlobalNotification, GlobalNotificationHub } from "../services/GlobalMessageHub";
import { MessageHubContext } from "../services/notification/MessageHubHandler";
import { GlobalStore, GlobalStoreData } from "../services/stores/GlobalStore";
import { CommonElements } from "../models/CommonElements";
import { Link } from "react-router-dom";
import { useAppContext } from "../services/context/AppContext";
import { MobileComponentDetector } from "../pages/comingsoon/MobilePage";

interface HeaderComponentProps {
    currentPage: ConceptType;
    currentPageType: PageType;
}

export const HeaderComponent = (props: HeaderComponentProps) => {

    const [workspaceCode, setWorkspaceCode] = useState(GlobalStore.getWorkspaceCodeIfAvailable());
    const [workspaceName, setWorkspaceName] = useState(GlobalStore.getWorkspaceNameIfAvailable());

    useEffect(() => {
        const messageHubContext = MessageHubContext()
            .Subscribe(GlobalStore, (workspaceData: GlobalStoreData) => {
                if (workspaceCode !== workspaceData.currentWorkspace.workspaceCode) {
                    setWorkspaceCode(workspaceData.currentWorkspace.workspaceCode);
                    setWorkspaceName(workspaceData.currentWorkspace.workspaceName)
                }
            });

        return () => messageHubContext.Dispose();
    }, [workspaceCode, props.currentPage]);


    if (props.currentPageType === PageType.Dashboard) {

        return <HeaderDashboardComponent workspaceCode={workspaceCode} workspaceName={workspaceName} />
    }

    return (
        <HeaderConceptComponent workspaceCode={workspaceCode} workspaceName={workspaceName} header={props} />
    )
}

interface HeaderDashboardComponentProps {
    workspaceCode: string;
    workspaceName: string;
}

const HeaderDashboardComponent = (props: HeaderDashboardComponentProps) => {

    let workspaceNameDisplay = props.workspaceName;
    const cut = 30;
    if (workspaceNameDisplay.length > cut) {
        workspaceNameDisplay = workspaceNameDisplay.substring(0, cut) + " ...";
    }

    return (
        <header className={"navbar navbar-expand-md navbar-dark sticky-top p-0 bg-header"} style={{ height: "5.5rem" }}>
            <nav className="d-flex flex-nowrap align-items-center flex-lg-nowrap w-100 h-100" aria-label="Main navigation">
                <div className="display-mobile ps-1 pt-1">
                    <div role="button" style={{ border: 0 }}
                        data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" aria-label="Toggle navigation">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="light-icon" fill="currentColor" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path>
                        </svg>                        
                    </div>
                </div>
                <div>
                    <div className="d-flex flex-column ms-3 ps-3">
                        <div className="fw-small-400 fw-fade">{workspaceNameDisplay}</div>
                        <div className="fw-xxlarge-700 fw-color-light" style={{ marginTop: "-7px" }}>Dashboard</div>
                    </div>
                </div>
                <div className="d-none d-md-flex flex-fill align-items-center">
                    <div className="flex-fill"></div>
                    <div className="me-4" style={{ whiteSpace: "nowrap" }}>
                        <Link to={"/w/" + props.workspaceCode + "/team/"} className="btn btn-primary btn-concept btn-mod-border ms-2">Invite Team members</Link>
                    </div>
                </div>
            </nav>
        </header>
    )
}

interface HeaderConceptComponentProps {
    header: HeaderComponentProps;

    workspaceCode: string;
    workspaceName: string;
}

const HeaderConceptComponent = (props: HeaderConceptComponentProps) => {

    const [pageDescription, setPageDescription] = useState("All " + ConceptTypeEnumLabel.get(props.header.currentPage) + "s");
    const [isMobile, setIsMobile] = useState(HelperExtension.IsMobile());
    const context = useAppContext();
    // const [workspaceCode, setWorkspaceCode] = useState(GlobalStore.getWorkspaceCodeIfAvailable());
    // const [workspaceName, setWorkspaceName] = useState(GlobalStore.getWorkspaceNameIfAvailable());

    const OnAddConceptClick = () => {
        GlobalNotificationHub.sendMessage(GlobalNotifications.AddNewConcept);
        return true;
    };

    useEffect(() => {
        const messageHubContext = MessageHubContext()
            .ListenGlobalNotification([GlobalNotifications.FolderSelectChange, GlobalNotifications.FolderDefaultSelection], OnGlobalNotification);

        return () => messageHubContext.Dispose();
    }, [props.header.currentPage]);

    const OnGlobalNotification = useCallback((notification: GlobalNotification) => {

        const selectedFolder = notification.notificationData as FolderDataModel;
        if (!selectedFolder) {
            return;
        }

        const folderDescription = HelperExtension.GetSystemFolderDescription(selectedFolder, ConceptTypeEnumLabel.get(props.header.currentPage));
        setPageDescription(folderDescription);
    }, [props.header.currentPage]);

    if (!props.workspaceName) {
        return (
            <header className={"navbar navbar-expand-md navbar-dark sticky-top p-0 bg-header"} style={{ height: "5.5rem" }}>
                <nav className="d-flex flex-nowrap flex-lg-nowrap w-100 h-100" aria-label="Main navigation">

                </nav>
            </header>
        )
    }

    const conceptDescription = HelperExtension.GetConceptName(props.header.currentPage, 0);

    let workspaceNameDisplay = props.workspaceName;
    const cut = 30;
    if (workspaceNameDisplay.length > cut) {
        workspaceNameDisplay = workspaceNameDisplay.substring(0, cut) + " ...";
    }

    let pageDescriptionDisplay = pageDescription;
    if (pageDescriptionDisplay.length > cut) {
        pageDescriptionDisplay = pageDescriptionDisplay.substring(0, cut) + " ...";
    }

    if (isMobile) {
        return (
            <header className="navbar navbar-dark sticky-top p-0 bg-header" style={{ height: "5.5rem" }}>
                <div className="display-mobile ps-1 pt-1">
                    <div className="navbar-toggler" role="button" style={{ border: 0 }}
                        data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" aria-label="Toggle navigation">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="bi" fill="currentColor" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path>
                        </svg>
                    </div>
                </div>
                <div className="d-flex flex-column ms-3 header-content">
                    {/* <div className="fw-small-400 fw-fade">{workspaceNameDisplay}</div> */}
                    <div className="fw-xlarge-700 fw-color-light header-title">{pageDescriptionDisplay}</div>
                </div>
                <div className="dropdown display-tab">
                    <div className="navbar-toggler" style={{ color: "white", border: 0 }}
                        role="button"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="true"
                        aria-expanded="false">
                        <span>
                            <svg className="bi" width="1.5rem" height="1.5rem" fill="currentcolor" aria-hidden="true"><use xlinkHref="#three-dots-icon"></use></svg>
                        </span>
                    </div>
                    <ul className="dropdown-menu dropdown-menu-end mt-2">
                        <li><a className="dropdown-item" href={"/w/" + props.workspaceCode + "/team/"} >Invite Team members</a></li>
                        <li><hr className="dropdown-divider" /></li>
                        <li>
                            <div role="button" className="dropdown-item" data-bs-toggle="modal"
                                data-bs-target={CommonElements.ModalElementTarget} onClick={OnAddConceptClick}>
                                {HelperExtension.GetConceptHeaderText(props.header.currentPage, false)}
                            </div>
                        </li>
                    </ul>
                </div>
                <MobileComponentDetector onMobileChange={(newValue) => { if (isMobile !== newValue) setIsMobile(newValue) }} />
            </header>
        );
    }

    return (
        <header className={"navbar sticky-top p-0 bg-header"} style={{ height: "5.5rem" }}>
            <nav className="d-flex flex-nowrap flex-lg-nowrap w-100 h-100" aria-label="Main navigation">
                <div className="search-section-size header-full-nav d-flex">
                    <div className="display-mobile ps-1 pt-3">
                        <a className="navbar-toggler" type="button" style={{ border: 0 }}
                            data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" aria-label="Toggle navigation">
                            <svg className="pe-none white-icon" width="24" height="24" role="img" aria-label="Drag"><use xlinkHref="#three-lines-icon"></use></svg>
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="bi" fill="currentColor" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path>
                            </svg> */}
                        </a>
                    </div>
                    <div className="d-flex flex-column ms-3 header-content">
                        <div className="fw-small-400 fw-fade">{workspaceNameDisplay}</div>
                        <div className="fw-xxlarge-700 fw-color-light header-title" style={{ marginTop: "-7px" }}>{conceptDescription}</div>
                    </div>
                </div>
                <div className="d-lg-flex me-4 header-separator">
                    <svg className="pe-none" width="19" height="100%"
                        role="img" fill="blue"><use xlinkHref="#header-separator"></use></svg>
                </div>
                <div className="w-100 px-3 d-flex align-items-center">
                    <div className="fw-xxlarge-700 fw-color-light" style={{ lineHeight: "1.8rem" }}>
                        {pageDescriptionDisplay}
                    </div>
                    <div className="flex-fill"></div>
                    <div>
                        <div className="display-full" style={{ whiteSpace: "nowrap" }}>
                            <Link to={"/w/" + props.workspaceCode + "/team/"} className="btn btn-primary btn-concept btn-mod-border ms-2">Invite Team members</Link>

                            <button id={CommonElements.CreateConcept} type="button" className="btn btn-concept-outline ms-2" data-bs-toggle="modal" data-bs-target={CommonElements.ModalElementTarget} onClick={OnAddConceptClick}>
                                <svg className="pe-none me-2 align-middle" width="17" height="16" role="img" aria-label="Problems"><use xlinkHref="#add-concept-icon"></use></svg>
                                <span className="align-middle">{HelperExtension.GetConceptHeaderText(props.header.currentPage, false)}</span>
                            </button>
                        </div>
                        <div className="dropdown display-tab">
                            <a className="navbar-toggler" style={{ color: "white", border: 0 }}
                                role="button"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="true"
                                aria-expanded="false">
                                <span>
                                    <svg className="bi" width="1.5rem" height="1.5rem" fill="currentcolor" aria-hidden="true"><use xlinkHref="#three-dots-icon"></use></svg>
                                </span>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end mt-2">
                                <li><a className="dropdown-item" href={"/w/" + props.workspaceCode + "/team/"} >Invite Team members</a></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li>
                                    <div role="button" className="dropdown-item" data-bs-toggle="modal"
                                        data-bs-target={CommonElements.ModalElementTarget} onClick={OnAddConceptClick}>
                                        {HelperExtension.GetConceptHeaderText(props.header.currentPage, false)}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <MobileComponentDetector onMobileChange={(newValue) => { if (isMobile !== newValue) setIsMobile(newValue) }} />
        </header>
    )
}

export const HeaderComponent1 = (props: HeaderComponentProps) => {

    //const pageDescription = ConceptTypeEnumLabel.get(props.currentPage);

    //const context = useAppContext();

    const workspaceCode = GlobalStore.getWorkspaceCodeIfAvailable();
    const workspaceName = GlobalStore.getWorkspaceNameIfAvailable();
    const [pageDescription, setPageDescription] = useState("All " + ConceptTypeEnumLabel.get(props.currentPage) + "s");

    const OnAddConceptClick = () => {
        GlobalNotificationHub.sendMessage(GlobalNotifications.AddNewConcept);
    };

    useEffect(() => {
        const messageHubContext = MessageHubContext()
            .Subscribe(GlobalNotificationHub, OnGlobalNotification);

        return () => messageHubContext.Dispose();
    });

    const OnGlobalNotification = (notification: GlobalNotification) => {

        switch (notification.notificationType) {
            case GlobalNotifications.FolderSelectChange:
            case GlobalNotifications.FolderDefaultSelection:
                break;
            default:
                return;
        }

        const selectedFolder = notification.notificationData as FolderDataModel;
        if (!selectedFolder) {
            return;
        }

        const folderDescription = HelperExtension.GetSystemFolderDescription(selectedFolder, ConceptTypeEnumLabel.get(props.currentPage));
        setPageDescription(folderDescription);
    }

    return (
        <header className={"navbar navbar-expand-md navbar-dark sticky-top p-0 bg-header"} style={{ height: "5.5rem" }}>
            <nav className="d-flex flex-nowrap flex-lg-nowrap" aria-label="Main navigation">

                <button className="navbar-toggler" type="button"
                    data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="search-bar-bg ">a</div>

                <div className="d-flex flex-column ps-3 text-center text-md-start">
                    <div className="fw-small-400 fw-fade ">{workspaceName}</div>
                    <div className="fw-xxlarge-900 fw-color-light" style={{ marginTop: "-5px" }}>{pageDescription}</div>
                </div>
                <div className="arrow-right">ss</div>

                {/* <a className="navbar-brand" href="/#">All {pageDescription}s</a> */}

                <div className="collapse navbar-collapse" id="navbarsExample02">
                    {/* <ul className="navbar-nav me-auto">
                        <li className="nav-item">
                            <a className="nav-link " href="/#">Mobile site</a>
                        </li>
                    </ul>  */}
                    <div className="me-auto"></div>
                    <div>
                        <Link to={"/w/" + workspaceCode + "/team/"} className="btn btn-primary btn-concept btn-mod-border mx-2">Invite Team members</Link>

                        <button id={CommonElements.CreateConcept} type="button" className="btn btn-concept-outline" data-bs-toggle="modal" data-bs-target={CommonElements.ModalElementTarget} onClick={OnAddConceptClick}>
                            <svg className="pe-none me-2 align-middle" width="17" height="16" role="img" aria-label="Problems"><use xlinkHref="#add-concept-icon"></use></svg>
                            <span className="align-middle">{HelperExtension.GetConceptHeaderText(props.currentPage, false)}</span>
                        </button>
                    </div>
                </div>
                <button className="navbar-toggler d-flex d-md-none p-2" type="button"
                    data-bs-toggle="collapse" data-bs-target="#searchBarComponent" aria-controls="searchBarComponent" aria-label="Toggle search">
                    <span>
                        <svg className="bi" width="1.5rem" height="1.5rem" fill="currentcolor" aria-hidden="true"><use xlinkHref="#three-dots-icon"></use></svg>
                    </span>
                </button>
            </nav>
        </header>
    )
}