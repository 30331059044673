import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { AuthContextProps, useAuth } from "react-oidc-context"
import { WorkspaceDisplay } from "../components/workspace/WorkspaceDisplay";
import { ConceptType, GlobalNotifications } from "../models/common/CommonEnums";
import { AccountAccessType, WorkspaceDataModel } from "../models/data/WorkspaceDataModel";
import { MessageService } from "../services/messaging/MessageService";
import { IHubSubcription, MessageHubContext, MessageHubHandler } from "../services/notification/MessageHubHandler";
import { GlobalStore } from "../services/stores/GlobalStore";
import { WorkspaceStore } from "../services/stores/Workspace/WorkspaceStore";
import { InitializeTheme } from "../services/ThemeService";
import { LoadingPage } from "./loading/LoadingPage";
import workspace from "../../assets/images/app/workspace.svg";
import { useNavigate } from "react-router-dom";
import { GlobalNotificationHub } from "../services/GlobalMessageHub";
import { AuthRedirectCache } from "../services/authentication/AuthRedirectCache";
import { CommonElements } from "../models/CommonElements";
import { HelpUsComponent } from "../components/notification/HelpUsComponent";
import { MobileComponentDetector } from "./comingsoon/MobilePage";
import { ModalComponent } from "../components/modal/ModalComponent";
import { WorkspaceEditorNotificationModel } from "../models/communication/internal/WorkspaceEditorNotificationModel";
import { MessageHubOwner } from "../services/messaging/MessageHubOwner";
import { IServerMessage, MessageType } from "../models/message/IServerMessage";
import { AlertTimerComponent } from "../components/notification/AlertTimerComponent";

export enum OnboardingCustomAction {
    None,
    Feedback,
    TeamInvite
}

interface OnboardingData {
    conceptType: ConceptType;
    customAction?: OnboardingCustomAction;
}

interface WorkspacePageProps {
    onboarding?: OnboardingData;
}

export const WorkspacePage = (props: WorkspacePageProps) => {
    const auth = useAuth();

    const [isReadyToUse, setIsReadyToUse] = useState(false);

    useEffect(() => {
        if (!auth.isLoading && auth.user?.access_token != null) {
            const tokenUpdated = MessageService.UpdateAcessToken(auth.user?.access_token as string);
            if (tokenUpdated) {
                MessageService.sendMessage({
                    messageType: MessageType.Ping,
                    requestData: {}
                } as IServerMessage);
            }
            else {
                setIsReadyToUse(true);
            }
        }

    }, [auth]);

    useEffect(() => {
        const hubContext = MessageHubContext()
            .ListenMessage(MessageHubOwner, {
                MessageType: MessageType.Pong, OnReceive: () => {
                    setIsReadyToUse(true);
                }
            } as IHubSubcription)


        return () => hubContext.Dispose();
    }, []);

    const debouncedAuthCheckHandler = useMemo(
        () => debounce(async () => {

            try {
                const sessionStatus = await auth.querySessionStatus();
                if (sessionStatus) {
                    auth.signinSilent();
                }
                else {
                    auth.signinRedirect({
                        state: AuthRedirectCache.create()
                    });
                }
            } catch {
                auth.signinRedirect({
                    state: AuthRedirectCache.create()
                });
            }
        }, 200)
        , [auth]);

    useEffect(() => {
        return () => debouncedAuthCheckHandler.cancel();
    }, [debouncedAuthCheckHandler]);

    useEffect(() => {
        if (!auth.isAuthenticated) {
            if (!auth.isLoading) {
                debouncedAuthCheckHandler();
            }
        }
    }, [auth.isLoading, debouncedAuthCheckHandler]);


    InitializeTheme(ConceptType.Default);

    if (auth.isLoading || !auth.isAuthenticated || !isReadyToUse) {
        return (
            <LoadingPage />
        );
    }

    if (props.onboarding) {
        return (
            <OnboardingRedirectComponent {...props.onboarding} />
        )
    }

    return (
        <WorkspacePageContent authContext={auth} />
    );
}

interface WorkspacePageContentProps {
    authContext: AuthContextProps;
}

interface WorkspacePageContentState {
    workspaceList: WorkspaceDataModel[];
    forceReload: number;
}

const OnboardingRedirectComponent = (props: OnboardingData) => {

    const [workspaceStore] = useState(new WorkspaceStore());
    const navigate = useNavigate();

    useEffect(() => {

        const hubContext = MessageHubContext()
            .Subscribe(workspaceStore, (workspaceListResponse: WorkspaceDataModel[]) => {

                if (workspaceListResponse.length === 0) {
                    navigate("/workspace/");
                }
                else {

                    if (props.customAction === OnboardingCustomAction.Feedback) {
                        GlobalNotificationHub.queueDelayedAction(() => {
                            const createConcept = document.getElementById("system-folder-3");
                            if (createConcept) {
                                createConcept.click();
                            }
                        });
                    }
                    else if (props.customAction === OnboardingCustomAction.TeamInvite) {
                        GlobalNotificationHub.queueDelayedAction(() => {
                            navigate("/w/" + workspaceListResponse[0].workspaceCode + "/team/");
                        });
                    }
                    else {

                        GlobalNotificationHub.queueDelayedAction(() => {
                            const createConcept = document.getElementById(CommonElements.CreateConcept);
                            if (createConcept) {
                                createConcept.click();
                            }
                        });
                    }


                    switch (props.conceptType) {
                        case ConceptType.Insights:
                            navigate("/w/" + workspaceListResponse[0].workspaceCode + "/insights/");
                            break;
                        case ConceptType.Problems:
                            navigate("/w/" + workspaceListResponse[0].workspaceCode + "/problems/");
                            break;
                        case ConceptType.Solutions:
                            navigate("/w/" + workspaceListResponse[0].workspaceCode + "/solutions/");
                            break;
                    }
                }


            });

        workspaceStore.initialize();
        return () => hubContext.Dispose();
    }, []);


    return (
        <React.Fragment></React.Fragment>
    )
}


class WorkspacePageContent extends React.PureComponent<WorkspacePageContentProps, WorkspacePageContentState> {

    messageHubContext?: MessageHubHandler;
    workspaceStore?: WorkspaceStore;


    constructor(props: WorkspacePageContentProps) {
        super(props);

        this.OnWorkspaceLoaded = this.OnWorkspaceLoaded.bind(this);
        this.OpenCreateWorkspace = this.OpenCreateWorkspace.bind(this);

        this.state = {
            workspaceList: [],
            forceReload: 0
        } as WorkspacePageContentState;
    }

    componentDidMount() {
        GlobalStore.initialize();

        this.workspaceStore = new WorkspaceStore();

        this.messageHubContext = MessageHubContext()
            .Subscribe(this.workspaceStore, this.OnWorkspaceLoaded);
        this.workspaceStore.initialize();
    }

    componentWillUnmount() {
        this.messageHubContext?.Dispose();
        this.workspaceStore?.dispose();
    }


    OnWorkspaceLoaded(workspaceListResponse: WorkspaceDataModel[]) {
        this.setState({
            workspaceList: workspaceListResponse,
            forceReload: this.state.forceReload + 1
        });
    }

    OpenCreateWorkspace() {
        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.WorkspaceEditor, { workspaceStore: this.workspaceStore } as WorkspaceEditorNotificationModel);
    }

    render() {

        if (this.state.forceReload === 0) {
            return (
                <React.Fragment>
                    <LoadingPage />
                    <img src={workspace} style={{ display: "none" }} alt="Workspace" />
                </React.Fragment>
            )
        }

        const myWorkspaces = this.state.workspaceList.filter(w => w.accountAccessType === AccountAccessType.Owner);
        const sharedWorkspaces = this.state.workspaceList.filter(w => w.accountAccessType !== AccountAccessType.Owner);


        return (
            <div className="d-flex flex-nowrap">
                <main className="container-fluid p-0">
                    <div className="layout-main-content d-flex justify-content-center pt-5">
                        <div className="d-flex flex-column align-items-center wd-region">
                            <div className="wd d-flex flex-column align-items-center">
                                <div style={{ height: "280px" }}>
                                    <img src={workspace} alt="My workspace" style={{ maxWidth: "100%", height: "280px" }} />
                                </div>
                                <div className="w-100 pt-2">
                                    <div className="fw-xlarge-700 ms-2 my-3">My workspaces ({myWorkspaces.length})</div>
                                </div>
                                {
                                    myWorkspaces.map(workspace => {
                                        return <WorkspaceDisplay
                                            key={workspace.workspaceID}
                                            workspace={workspace}
                                            workspaceStore={this.workspaceStore}
                                            isSharedWorkspace={false} />
                                    })
                                }
                                <div className="workspace-add-region dark-icon fw-reg-500 fw-dark text-center ms-2 my-3 p-2 w-100" role="button"
                                    data-bs-toggle="modal" data-bs-target={CommonElements.ModalElementTarget} onClick={this.OpenCreateWorkspace}>
                                    <svg className="pe-none" width="17" height="16" role="img" aria-label="Problems"><use xlinkHref="#add-concept-icon"></use></svg>
                                    <span className="ps-2 align-middle">Add a new workspace</span>
                                </div>
                            </div>
                            {
                                (sharedWorkspaces.length > 0) &&
                                <div className="wd d-flex flex-column align-items-center">
                                    <div className="w-100 pt-2">
                                        <div className="fw-xlarge-700 ms-2 my-3">Workspaces shared with you ({sharedWorkspaces.length})</div>
                                    </div>
                                    {
                                        sharedWorkspaces.map(workspace => {
                                            return <WorkspaceDisplay
                                                key={workspace.workspaceID}
                                                workspace={workspace}
                                                workspaceStore={this.workspaceStore}
                                                isSharedWorkspace={true} />
                                        })
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </main>

                {/* <WorkspaceEditor workspaceStore={this.workspaceStore as WorkspaceStore} /> */}
                <ModalComponent />
                <HelpUsComponent />
                {/* <MobileComponentDetector /> */}
                <AlertTimerComponent />
                {/* <PreReleaseComponent /> */}
            </div>
        );
    }
}