import React, { useEffect, useState } from "react"
import { GlobalNotifications } from "../../models/common/CommonEnums";
import { GlobalNotification } from "../../services/GlobalMessageHub";
import { MessageHubContext } from "../../services/notification/MessageHubHandler";
import { KeyboardService } from "../../services/shortcut/KeyboardService";
import { ModalDefinitionModel } from "./ModalComponent";
import { ImageScribbleHandler } from "./handlers/ImageScribbleHandler";
import { ImageScribbleNotificationModel } from "../../models/communication/internal/ImageScribbleNotificationModel";
import { ImageFullscreenViewerHander } from "./handlers/ImageFullscreenViewerHander";
import { ImageFullscreenViewNotificationModel } from "../../models/communication/internal/ImageFullscreenViewNotificationModel";
import { ConceptLinkExistingHandler } from "./handlers/ConceptLinkExistingHandler";
import { ConceptLinkExistingNotificationModel } from "../../models/communication/internal/ConceptLinkExistingNotificationModel";


export const CustomModalComponent = () => {

    const [elementKey] = useState(crypto.randomUUID());
    const [displayModal, setDisplayModal] = useState(false);
    //const [currentScreenshot, setCurrentScreenshot] = useState<AttachmentDataModel>();
    //const [displayType, setDisplayType] = useState<GlobalNotifications>();


    const [modalDefinition, setModalDefinition] = useState<ModalDefinitionModel>();
    const [autoClose, setAutoClose] = useState(false);

    //const customModal = useRef<HTMLDivElement>(null);



    useEffect(() => {

        const hubContext = MessageHubContext()
            .ListenGlobalNotification([GlobalNotifications.DisplayScreenshotFullscreen, GlobalNotifications.DisplayImageScribble, GlobalNotifications.LinkConcept],
                (notification: GlobalNotification) => {
                    // const screenshot = notification.notificationData as AttachmentDataModel;
                    // if (screenshot) {
                    //     setDisplayModal(true);
                    //     setCurrentScreenshot(screenshot);
                    //     setDisplayType(notification.notificationType);
                    // }

                    switch (notification.notificationType) {
                        case GlobalNotifications.DisplayScreenshotFullscreen:
                            setModalDefinition(ImageFullscreenViewerHander(notification.notificationData as ImageFullscreenViewNotificationModel));
                            setDisplayModal(true);
                            setAutoClose(true);
                            break;
                        case GlobalNotifications.DisplayImageScribble:
                            setModalDefinition(ImageScribbleHandler(notification.notificationData as ImageScribbleNotificationModel, CloseModal));
                            setDisplayModal(true);
                            setAutoClose(false);
                            break;
                        case GlobalNotifications.LinkConcept:
                            setModalDefinition(ConceptLinkExistingHandler(notification.notificationData as ConceptLinkExistingNotificationModel, CloseModal));
                            setDisplayModal(true);
                            setAutoClose(true);
                            break;
                    }

                    //if (modalDefinition) {
                    //                        setDisplayModal(true);
                    //}

                });


        return () => hubContext.Dispose();
    }, []);


    useEffect(() => {

        if (displayModal) {
            KeyboardService.hasFocus(elementKey)
        }
        else {
            KeyboardService.lostFocus(elementKey);
        }
        //KeyboardService.hasFocus()

    }, [elementKey, displayModal]);

    const CloseModal = () => {
        setDisplayModal(false);
        setModalDefinition(undefined);
    }


    if (!displayModal) {
        return <React.Fragment></React.Fragment>
    }

    return (
        <React.Fragment>
            <div className="fade show">
                <div id="screenshotDisplayModal" className="screenshot-fullscreen-overlay"
                    onClick={CloseModal}></div>
                <div className="screenshot-fullscreen" onClick={() => {
                    if (autoClose) {
                        CloseModal();
                    }
                }}>
                    <div className="d-flex align-items-center justify-content-center text-white h-100">
                        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                            {
                                (modalDefinition) &&
                                modalDefinition.modalContent
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}