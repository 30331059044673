import React, { memo, useEffect, useRef, useState } from "react"
import { GlobalNotifications } from "../../models/common/CommonEnums";
import { ConceptEditNotificationModel } from "../../models/communication/internal/ConceptEditNotificationModel";
import { ConceptViewerNotificationModel } from "../../models/communication/internal/ConceptViewerNotificationModel";
import { LinkToNewConceptNotificationModel } from "../../models/communication/internal/LinkToNewConceptNotificationModel";
import { useAppContext } from "../../services/context/AppContext";
import { GlobalNotification } from "../../services/GlobalMessageHub";
import { MessageHubContext } from "../../services/notification/MessageHubHandler";
import { FolderAddHandler } from "./handlers/FolderAddHandler";
import { ConceptEditHandler } from "./handlers/ConceptEditHandler";
import { ConceptLinkHandler } from "./handlers/ConceptLinkToNewHandler";
import { ConceptViewHandler } from "./handlers/ConceptViewHandler";
import { ConceptAddHandler } from "./handlers/ConceptAddHandler";
import { KeyboardService, ModalOpenCloseWatcher } from "../../services/shortcut/KeyboardService";
import { ConfirmNotificationModel } from "../../models/communication/internal/ConfirmNotificationModel";
import { ConfirmHandler } from "./handlers/ConfirmHandler";
import { CommonElements } from "../../models/CommonElements";
import { LogCollector } from "../../services/logger/LogCollector";
import { PreReleaseHandler } from "./handlers/PreReleaseHandler";
import { WorkspaceEditorHandler } from "./handlers/WorkspaceEditorHandler";
import { WorkspaceEditorNotificationModel } from "../../models/communication/internal/WorkspaceEditorNotificationModel";
import { FeedbackEditorHandler } from "./handlers/FeedbackEditorHandler";
import { FeedbackSettingsNotificationModel } from "../../models/communication/internal/FeedbackSettingsNotificationModel";
import { VoyceFeedbackHandler } from "./handlers/VoyceFeedbackHandler";
import { WorkspaceInvitationHandler } from "./handlers/WorkspaceInvitationHandler";
import { ModalLoadingComponent } from "./ModalLoadingComponent";

export enum ModalSize {
    XLargeScrollable = 1,
    Xlarge = 2,
    Small = 3
}

export interface ModalDefinitionModel {

    modalContent: JSX.Element;
    modalSize: ModalSize;
    OnModalClosing?: () => void;

    modalPosition?: string;
    overrideConceptTheme?: string;
}

export const ModalComponent: React.FunctionComponent
    = memo(function ModalComponentImplementation() {

        const context = useAppContext();
        const [modalState, setModalState] = useState({
            isOpen: false,
            modalType: GlobalNotifications.None
        });

        const [currentModalDefinition, setCurrentModalDefinition] = useState<ModalDefinitionModel>();


        useEffect(() => {
            const messageHubContext = MessageHubContext()
                .ListenGlobalNotification([
                    GlobalNotifications.AddNewConcept,
                    GlobalNotifications.AddNewFolder,
                    GlobalNotifications.ViewConcept,
                    GlobalNotifications.EditConcept,
                    GlobalNotifications.LinkToNewConcept,
                    GlobalNotifications.ConfirmNotification,
                    GlobalNotifications.WorkspaceEditor,
                    GlobalNotifications.FeedbackLogoEditor,
                    GlobalNotifications.WorkspaceInvitation,

                    //OneOffNotications
                    GlobalNotifications.PreRelease,
                    GlobalNotifications.VoyceFeedback],
                    OnGlobalNotification);

            return () => messageHubContext.Dispose();
        }, []);

        const OnGlobalNotification = (notification: GlobalNotification) => {
            //setOverrideDataTheme(undefined);
            setCurrentModalDefinition(undefined);

            switch (notification.notificationType) {
                case GlobalNotifications.AddNewConcept:
                    const addConceptModalDefinition = ConceptAddHandler({ context });
                    setCurrentModalDefinition(addConceptModalDefinition);
                    break;
                case GlobalNotifications.LinkToNewConcept:
                    const linkToConceptData = notification.notificationData as LinkToNewConceptNotificationModel;
                    const linkConceptModelDefinition = ConceptLinkHandler({ notification: linkToConceptData });
                    setCurrentModalDefinition(linkConceptModelDefinition);
                    break;
                case GlobalNotifications.AddNewFolder:
                    const addNewFolderModalDefinition = FolderAddHandler({ conceptType: context.conceptType })
                    setCurrentModalDefinition(addNewFolderModalDefinition);
                    break;
                case GlobalNotifications.ViewConcept:
                    const notificationData = notification.notificationData as ConceptViewerNotificationModel;
                    const viewConceptModalDefinition = ConceptViewHandler({ notification: notificationData });
                    setCurrentModalDefinition(viewConceptModalDefinition);
                    break;
                case GlobalNotifications.EditConcept:
                    const notificationEditData = notification.notificationData as ConceptEditNotificationModel;
                    const editConceptModalDefinition = ConceptEditHandler({ notification: notificationEditData });
                    setCurrentModalDefinition(editConceptModalDefinition);
                    break;
                case GlobalNotifications.ConfirmNotification:
                    const notificationConfirmData = notification.notificationData as ConfirmNotificationModel;
                    const confirmModalDefinition = ConfirmHandler({ notification: notificationConfirmData });
                    setCurrentModalDefinition(confirmModalDefinition);
                    break;
                case GlobalNotifications.WorkspaceEditor:
                    const workspaceData = notification.notificationData as WorkspaceEditorNotificationModel;
                    const workspaceModalDefinition = WorkspaceEditorHandler({ notification: workspaceData });
                    setCurrentModalDefinition(workspaceModalDefinition);
                    break;
                case GlobalNotifications.FeedbackLogoEditor:
                    const feedbackSettingsNotification = notification.notificationData as FeedbackSettingsNotificationModel;
                    const feedbackEditorModelDefinition = FeedbackEditorHandler(feedbackSettingsNotification);
                    setCurrentModalDefinition(feedbackEditorModelDefinition);
                    break;
                case GlobalNotifications.WorkspaceInvitation:
                    const workspaceInviationModalDefinition = WorkspaceInvitationHandler();
                    setCurrentModalDefinition(workspaceInviationModalDefinition);
                    break;

                //OneOff notifications
                case GlobalNotifications.PreRelease:
                    // not in use
                    const preReleaseModalDefinition = PreReleaseHandler();
                    setCurrentModalDefinition(preReleaseModalDefinition);
                    break;
                case GlobalNotifications.VoyceFeedback:
                    const voyceFeedbackModalDefinition = VoyceFeedbackHandler();
                    setCurrentModalDefinition(voyceFeedbackModalDefinition);
                    break;
                default:
                    return;

            }

            LogCollector.LogMessage("ModalComponent: OnGlobalNotification")

            setModalState({
                isOpen: true,
                modalType: notification.notificationType
            });
        }

        const GetModalContent = () => {

            if (currentModalDefinition) {
                switch (modalState.modalType) {
                    case GlobalNotifications.AddNewConcept:
                    case GlobalNotifications.AddNewFolder:
                    case GlobalNotifications.ViewConcept:
                    case GlobalNotifications.EditConcept:
                    case GlobalNotifications.LinkToNewConcept:
                    case GlobalNotifications.ConfirmNotification:
                    case GlobalNotifications.WorkspaceEditor:
                    case GlobalNotifications.FeedbackLogoEditor:
                    case GlobalNotifications.WorkspaceInvitation:
                    case GlobalNotifications.PreRelease:
                    case GlobalNotifications.VoyceFeedback:
                        return currentModalDefinition.modalContent;
                }
            }

            return (<div></div>);
        }

        const GetModalSize = () => {

            if (currentModalDefinition?.modalSize) {
                switch (currentModalDefinition.modalSize) {
                    case ModalSize.XLargeScrollable:
                        return "modal-xl modal-fullscreen-lg-down modal-dialog-scrollable";
                    case ModalSize.Xlarge:
                        return "modal-xl modal-fullscreen-lg-down " + (currentModalDefinition.modalPosition ?? "");
                    case ModalSize.Small:
                        return "modal-dialog-centered modal-sm";
                }
            }

            return "modal-dialog-centered modal-sm";
        }

        // #REF-example
        const modalReference = useRef<HTMLDivElement>(null);

        useEffect(() => {
            if (modalState.isOpen) {
                if (modalReference?.current) {
                    modalReference.current.focus();
                }
            }
        }, [modalState, modalReference])

        useEffect(() => {
            const currentModalReference = modalReference?.current;
            if (!currentModalReference) {
                return;
            }

            const OnModalBSHideEvent = (event: any) => {

                if (currentModalDefinition?.OnModalClosing) {

                    if (KeyboardService.canClose()) {
                        setTimeout(() => {
                            if (!KeyboardService.canClose()) {
                                return;
                            }
                            if (currentModalDefinition?.OnModalClosing) {
                                currentModalDefinition.OnModalClosing();
                            }
                        }, 100);

                        event.preventDefault();
                        return false;
                    }
                }
            }

            currentModalReference.addEventListener("hide.bs.modal", OnModalBSHideEvent);

            return () => {
                if (currentModalReference) {
                    currentModalReference.removeEventListener("hide.bs.modal", OnModalBSHideEvent);
                }
            }
        }, [modalReference, currentModalDefinition]);

        useEffect(() => {
            const currentModalReference = modalReference?.current;
            if (!currentModalReference) {
                return;
            }
            const OnModalBSHideEvent = (event: any) => {
                setModalState({ ...modalState, isOpen: false });
            }

            currentModalReference.addEventListener("hidden.bs.modal", OnModalBSHideEvent);

            return () => {
                if (currentModalReference) {
                    currentModalReference.removeEventListener("hidden.bs.modal", OnModalBSHideEvent);
                }
            }

        }, [modalReference])

        useEffect(() => {

            const currentModalReference = modalReference?.current;

            if (!currentModalReference) {
                return;
            }

            const HackForCancelFromConfirmation = () => {

                if (!modalState.isOpen) {
                    if (currentModalDefinition) {
                        setModalState({
                            ...modalState,
                            isOpen: true,
                        });
                    }
                }

            }

            if (modalState.modalType === GlobalNotifications.ViewConcept) {
                currentModalReference.addEventListener("show.bs.modal", HackForCancelFromConfirmation);
            }

            return () => {
                if (currentModalReference) {
                    currentModalReference.removeEventListener("hide.bs.modal", HackForCancelFromConfirmation);
                }
            }

        }, [modalReference, modalState, currentModalDefinition]);

        useEffect(() => {

            LogCollector.LogWarn("KeyboardService.attachAutoCloseForModal");
            KeyboardService.attachAutoCloseForModal(modalReference);

            return () => KeyboardService.releaseModal(modalReference);
        }, [modalReference, modalReference.current]);

        return (
            <div ref={modalReference} className="modal fade" id={CommonElements.ModalElement} tabIndex={-1}>
                <div className={"modal-dialog " + GetModalSize()} >
                    <div className="modal-content modal-border" data-theme={currentModalDefinition?.overrideConceptTheme}>
                        {
                            (modalState.isOpen) &&
                            (
                                <React.Fragment>
                                    <GetModalContent />
                                    <ModalLoadingComponent />
                                    <ModalOpenCloseWatcher />
                                </React.Fragment>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    })