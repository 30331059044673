import React, { useEffect } from "react";
import { memo } from "react";
import { ConceptType, GlobalNotifications } from "../../../models/common/CommonEnums";
import { ConceptComplexity, ConceptDataModel } from "../../../models/ConceptDataModel";
import { useAppContext } from "../../../services/context/AppContext";
import { HelperExtension } from "../../../services/HelperExtension";
import { MessageHubContext } from "../../../services/notification/MessageHubHandler";
import { ConceptLinkStoreData } from "../../../services/stores/ConceptLink/ConceptLinkStore";
import { ConceptComplexityService } from "../../elements/ComplexityViewerComponent";
import { RangeSelectorComponent } from "../../elements/RangeSelectorComponent";
import { SaveButtonComponent, TempValidatorType } from "../../elements/SaveButtonComponent";
import { SectionHeaderComponent, SectionHeaderIconSize } from "../../elements/SectionHeaderComponent";
import { FieldType, TextBoxComponent } from "../../elements/TextBoxComponent";
import { TextBoxGroupComponent } from "../../elements/TextBoxGroupComponent";
import { ConceptAttachmentComponent } from "../../uploader/ConceptAttachmentComponent";
import { ConceptHandlerComponentV2Props } from "../ConceptEditorComponent";
import { ConceptLinkSection } from "../sections/ConceptLinkSection";
import { SolutionCustomLinkComponent } from "./elements/SolutionCustomLinkComponent";
import { SolutionPreviewComponent } from "./elements/SolutionPreviewComponent";
import { ConceptImageAttachmentComponent } from "../../uploader/ConceptImageAttachmentComponent";
import { GlobalNotificationHub } from "../../../services/GlobalMessageHub";
import { TextBoxRichTextComponent } from "../../elements/RichTextBox/TextBoxRichTextComponent";

export const SolutionEditorComponent: React.FunctionComponent<ConceptHandlerComponentV2Props>
    = memo(
        function SolutionEditor({ conceptEditorService, isEditing }) {

            const context = useAppContext();
            const conceptEditorStore = conceptEditorService.conceptEditorStore;

            const SaveConcept = async () => {

                GlobalNotificationHub.sendMessage(GlobalNotifications.ModalLoading);

                if (!conceptEditorStore.storageState.solutionData?.useFreeText) {
                    const solutionDefinition = conceptEditorStore.storageState.solutionData;

                    if (solutionDefinition.believeText && solutionDefinition.resultText && solutionDefinition.evidenceText) {
                        conceptEditorStore.storageState.description =
                            "We believe that " + solutionDefinition.believeText +
                            " will result in " + solutionDefinition.resultText +
                            " measured by " + solutionDefinition.evidenceText +
                            ".";
                    }
                }

                await conceptEditorService.saveConcept(context);
            }

            useEffect(() => {

                // let currentValue = {
                //     conceptEffort: -1,
                //     conceptImpact: -1
                // };
                const hubContext = MessageHubContext()
                    .Subscribe(conceptEditorService.conceptEditorStore, (concept: ConceptDataModel) => {

                        if (!concept?.solutionData) {
                            return;
                        }

                        const conceptEffort = concept.solutionData?.conceptEffort ?? ConceptComplexity.NOTDEFINED;
                        const conceptImpact = concept.solutionData?.conceptImpact ?? ConceptComplexity.NOTDEFINED;

                        concept.conceptValue = new ConceptComplexityService(ConceptType.Solutions).calculateConceptValue(conceptImpact, conceptEffort);
                    })
                    .Subscribe(conceptEditorService.conceptLinkStore, (conceptLink: ConceptLinkStoreData) => {

                        if (!conceptEditorService.conceptEditorStore.storageState?.solutionData) {
                            return;
                        }

                        if (conceptLink.conceptLinkList.length === 0) {
                            if (conceptEditorService.conceptEditorStore.storageState.solutionData.conceptImpact !== ConceptComplexity.NOTDEFINED) {
                                conceptEditorService.conceptEditorStore.sendMessageCustom("solutionData.conceptImpact", ConceptComplexity.NOTDEFINED);
                            }
                            return;
                        }

                        const linkedProblems = conceptLink.conceptLinkList.filter(linkedConcept => linkedConcept.conceptType === ConceptType.Problems);
                        const totalImpact = linkedProblems.reduce((calculatedValue, problemConcept) => calculatedValue = calculatedValue + (problemConcept.conceptValue ?? 0), 0);

                        const newValue = linkedProblems.length > 0
                            ? Math.trunc(totalImpact / linkedProblems.length)
                            : 0;

                        if (conceptEditorService.conceptEditorStore.storageState.solutionData.conceptImpact !== newValue) {
                            conceptEditorService.conceptEditorStore.sendMessageCustom("solutionData.conceptImpact", (newValue as ConceptComplexity));
                        }
                    });


                return () => hubContext.Dispose();
            }, []);

            useEffect(() => {
                const hubContext = MessageHubContext()
                    .Subscribe(conceptEditorStore, (conceptModel: ConceptDataModel) => {

                        hubContext.Dispose();
                        if (conceptModel.solutionData?.useFreeText) {
                            global.window.triggerClick("freetext-tab");
                        }
                    });


                return () => hubContext.Dispose();
            }, []);

            const OnTabChange = (isFreeText: boolean) => {

                conceptEditorStore.sendMessageCustom("solutionData.useFreeText", isFreeText);
            }

            useEffect(() => {
                conceptEditorService.conceptLinkStore.initialize();
            }, []);

            return (
                <React.Fragment>
                    <div className="modal-header bg-header text-white">
                        <SectionHeaderComponent iconName={context.conceptIcon} header={HelperExtension.GetConceptHeaderText(context.conceptType, isEditing)} iconSize={SectionHeaderIconSize.Medium} headerStyle="icon-bright" />
                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body bg-white vc-editor concept-editor ms-2 vc-custom-scroll">
                        <div className="pt-3">
                            <SectionHeaderComponent iconName="#solution-icon" header="What's the idea or solution? *" iconSize={SectionHeaderIconSize.Medium} />
                            <div className="pt-2">
                                <TextBoxComponent fieldName="title" dataStore={conceptEditorStore} maxLength={300} autoFocus={true} autoFocusDelayed={true} />
                            </div>
                            {/* <div className="pt-1">
                                <ExamplesComponent
                                    exampleDescription=""
                                    exampleList={[
                                        "Changing the colour of the sale prices on the website from back to red",
                                        "Providing personalised content based on their past searches",
                                        "Displaying sale products on the homepage and an email campaign"
                                    ]}

                                />
                            </div> */}
                        </div>
                        <hr className="my-4 mx-auto" />
                        <ConceptLinkSection
                            conceptLinkStore={conceptEditorService.conceptLinkStore}
                            sourceConceptType={context.conceptType}
                            targetConceptType={ConceptType.Problems}
                            isEditing={true}

                            customization={{
                                singleItemHeaderText: "What problem does this solve?",
                                manyItemsHeaderText: "What problem does this solve?",

                                buttonIcon: "#link-icon",
                                buttonText: "Link",
                                useNormalSizeCard: true
                            }} />

                        <div className="pt-3">
                            <SolutionCustomLinkComponent conceptLinkStore={conceptEditorService.conceptLinkStore} />
                        </div>

                        <hr className="my-4 mx-auto" />
                        <div>
                            <SectionHeaderComponent iconName="#weightbar-icon" header="Effort required to implement this solution" iconSize={SectionHeaderIconSize.Medium} />
                            <div className="mt-3">
                                <RangeSelectorComponent
                                    header=""
                                    fieldName="solutionData.conceptEffort"
                                    conceptStore={conceptEditorStore}
                                    selectionList={[
                                        { value: ConceptComplexity.Low, description: "Low" },
                                        { value: ConceptComplexity.LowMedium, description: "Medium/Low", positionFix: "15px" },
                                        { value: ConceptComplexity.Medium, description: "Medium", positionFix: "" },
                                        { value: ConceptComplexity.MediumHigh, description: "Medium/High", positionFix: "" },
                                        { value: ConceptComplexity.High, description: "High" },
                                    ]}

                                />
                            </div>
                        </div>

                        <hr className="my-4 mx-auto" />
                        <SectionHeaderComponent iconName="#beaker-icon" header="Create your hypothesis" iconSize={SectionHeaderIconSize.Medium} />
                        <ul className="pt-3 nav">
                            <li className="tab-item" role="presentation">
                                <button className="tab-link fw-reg-500 active"
                                    id="structured-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#solution-structured-tab"
                                    type="button" role="tab"
                                    onClick={() => OnTabChange(false)}
                                    aria-controls="problem-structured-tab" aria-selected="true">Use structured format (recommended)</button>
                            </li>
                            <li className="tab-item">
                                <button className="tab-link fw-reg-500"
                                    id="freetext-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#solution-freetext-tab"
                                    type="button" role="tab"
                                    onClick={() => OnTabChange(true)}
                                    aria-controls="problem-freetext-tab" aria-selected="false">Use free text</button>

                            </li>
                        </ul>
                        <div className="tab-content p-2" id="myTabContent">
                            <div className="tab-pane fade show active" id="solution-structured-tab" role="tabpanel" aria-labelledby="solution-structured-tab" tabIndex={0}>
                                <div className="pt-3">
                                    <TextBoxGroupComponent
                                        label="We believe that"
                                        example={{
                                            exampleDescription: "The 'What' - What's the actual thing you are thinking of doing? ",
                                            exampleList: [
                                                "showing larger lifestyle product photos of our products in the real world",
                                                "emailing users personalized recommendations based on their past searches",
                                                "creating a more playful registration process on mobile"
                                            ]
                                        }}
                                        textBox={{
                                            fieldName: "solutionData.believeText",
                                            dataStore: conceptEditorStore,
                                            maxLength: 1000
                                        }} />
                                </div>
                                <div className="pt-3">
                                    <TextBoxGroupComponent
                                        label="Will result in"
                                        example={{
                                            exampleDescription: "The 'Result' - What action or behaviour do you want to change?",
                                            exampleList: [
                                                "more people purchasing lifestyle products",
                                                "a significant increase in click throughs from emails",
                                                "more people registering successfully"
                                            ]
                                        }}
                                        textBox={{
                                            fieldName: "solutionData.resultText",
                                            dataStore: conceptEditorStore,
                                            maxLength: 1000
                                        }} />
                                </div>
                                <div className="pt-3">
                                    <TextBoxGroupComponent
                                        label="Measured by"
                                        example={{
                                            exampleDescription: "The 'Evidence' - What numbers are we looking at moving?",
                                            exampleList: [
                                                "a 5% lift in the sell through rate of the 'lifestyle' category",
                                                "a 20% increase in the click through rate from those emails",
                                                "a 10% increase in successful registrations on mobile devices"]
                                        }}
                                        textBox={{
                                            fieldName: "solutionData.evidenceText",
                                            dataStore: conceptEditorStore,
                                            maxLength: 1000
                                        }} />
                                </div>
                                <div className="pt-3">
                                    <SolutionPreviewComponent conceptEditorStore={conceptEditorStore} />
                                </div>
                            </div>
                            <div className="tab-pane fade" id="solution-freetext-tab" role="tabpanel" aria-labelledby="solution-freetext-tab" tabIndex={0}>
                                <div className="pt-3">
                                    <TextBoxComponent fieldName="description" dataStore={conceptEditorStore} details={{ fieldType: FieldType.Text, numberOfRows: 5 }} maxLength={3500} />
                                </div>
                            </div>
                        </div>

                        {/* {
                            (!isEditing) &&
                            <React.Fragment>
                                <hr className="mt-4 mb-3 mx-auto" />
                                <div className="my-3">
                                    <SectionHeaderComponent iconName="#edit-icon" header={"Organize this " + context.conceptTypeDescription.toLowerCase()} />
                                    <div className="my-2">
                                        <FolderSelectionComponent dataStore={conceptEditorStore} />
                                    </div>
                                    <TagComponent conceptTagStore={conceptEditorService.tagStore} disableDelete={true} />
                                </div>
                            </React.Fragment>
                        } */}

                        <hr className="my-4 mx-auto" />
                        <div className="my-3">
                            <SectionHeaderComponent iconName="#edit-icon" header="Solution details" />
                            <div className="pt-2">
                                <TextBoxRichTextComponent fieldName="solutionData.solutionDetails" dataStore={conceptEditorStore} maxLength={3500} />
                            </div>
                        </div>
                        <div className="pt-1">
                            <div className="fw-reg-500">Optional details</div>
                            <div className="pt-2 fw-small-400 fw-secondary">
                                If possible define and costs, or time required. <br />
                                e.g. will cost approx $1500 and 10 hours worth of work from marketing
                            </div>
                            <div className="pt-2">
                                <TextBoxComponent fieldName="solutionData.effortDetails" dataStore={conceptEditorStore} maxLength={300} />
                            </div>
                        </div>
                        <hr className="my-4 mx-auto" />
                        <div className="my-3">
                            <ConceptImageAttachmentComponent attachmentStore={conceptEditorService.conceptAttachmentStore} />
                        </div>
                        <hr className="my-4 mx-auto" />
                        <div className="my-3">
                            <ConceptAttachmentComponent attachmentStore={conceptEditorService.conceptAttachmentStore} />
                        </div>

                        <hr className="my-4 mx-auto" />
                        <ConceptLinkSection conceptLinkStore={conceptEditorService.conceptLinkStore} sourceConceptType={context.conceptType} targetConceptType={ConceptType.Insights} isEditing={true} />
                    </div>
                    <div className="modal-footer d-flex justify-content-between">
                        <div className="mx-md-3 mx-1">
                            <SaveButtonComponent
                                validatorType={TempValidatorType.Solution}
                                notificationHub={conceptEditorStore}
                                disableAutoClose={true}
                                ButtonClick={SaveConcept} />
                        </div>
                        <div className="mx-md-3 mx-1">
                            <button type="button" className="btn btn-outline-primary btn-concept-outline btn-medium" data-bs-dismiss="modal">Cancel</button>
                        </div>
                        {/* <button type="button" className="btn btn-primary btn-concept" data-bs-dismiss="modal" onClick={this.SaveConcept} >Save</button> */}


                    </div>

                </React.Fragment>
            )
        }
    )