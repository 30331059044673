import _ from "lodash";
import React, { useEffect } from "react";
import { memo } from "react";
import { ConceptType, GlobalNotifications } from "../../../models/common/CommonEnums";
import { ConceptAffect, ConceptDataModel, ConceptComplexity } from "../../../models/ConceptDataModel";
import { useAppContext } from "../../../services/context/AppContext";
import { HelperExtension } from "../../../services/HelperExtension";
import { MessageHubContext } from "../../../services/notification/MessageHubHandler";
import { ConceptComplexityService } from "../../elements/ComplexityViewerComponent";
import { RangeSelectorComponent } from "../../elements/RangeSelectorComponent";
import { SaveButtonComponent, TempValidatorType } from "../../elements/SaveButtonComponent";
import { SectionHeaderComponent, SectionHeaderIconSize } from "../../elements/SectionHeaderComponent";
import { TextBoxComponent } from "../../elements/TextBoxComponent";
import { TextBoxGroupComponent } from "../../elements/TextBoxGroupComponent";
import { ConceptAttachmentComponent } from "../../uploader/ConceptAttachmentComponent";
import { ConceptHandlerComponentV2Props } from "../ConceptEditorComponent";
import { ConceptLinkSection, ConceptLinkSectionButtonOnly } from "../sections/ConceptLinkSection";
import { ConceptImageAttachmentComponent } from "../../uploader/ConceptImageAttachmentComponent";
import { ProblemDefinitionModel } from "../../../models/concept/ProblemDefinitionModel";
import { GlobalNotificationHub } from "../../../services/GlobalMessageHub";
import { TextBoxRichTextComponent } from "../../elements/RichTextBox/TextBoxRichTextComponent";

export const ProblemEditorComponent: React.FunctionComponent<ConceptHandlerComponentV2Props>
    = memo(
        function ProblemEditor({ conceptEditorService, isEditing }) {

            const context = useAppContext();
            const conceptEditorStore = conceptEditorService.conceptEditorStore;

            const SaveConcept = async () => {

                GlobalNotificationHub.sendMessage(GlobalNotifications.ModalLoading);

                const problemDefinition = conceptEditorStore.storageState.problemData;
                if (problemDefinition) {
                    let goalText = "";
                    let valueText = "";
                    let obstacleText = "";

                    if (problemDefinition.whenText) {
                        goalText = "When " + problemDefinition.whenText;
                    }

                    if (problemDefinition.wantText) {
                        goalText = (goalText)
                            ? goalText + ", they want " + problemDefinition.wantText + "."
                            : "They want " + problemDefinition.wantText + ".";
                    }
                    else if (goalText) {
                        goalText = goalText + "."
                    }

                    if (problemDefinition.soText) {
                        valueText = "So they can " + problemDefinition.soText + ".";
                    }

                    if (problemDefinition.becauseText) {
                        obstacleText = "But they can't because " + problemDefinition.becauseText + ".";
                    }

                    const descriptionJson = {
                        g: goalText,
                        v: valueText,
                        o: obstacleText
                    } as ProblemDefinitionModel;

                    conceptEditorStore.storageState.description = JSON.stringify(descriptionJson);
                }
                // if (!conceptEditorStore.storageState.problemData?.useFreeText) {
                //     const problemDefinition = conceptEditorStore.storageState.problemData;

                //     if (problemDefinition.whenText && problemDefinition.wantText && problemDefinition.soText && problemDefinition.becauseText) {
                //         conceptEditorStore.storageState.description =
                //             "When I am " + problemDefinition.whenText +
                //             " I want to " + problemDefinition.wantText +
                //             " so I can " + problemDefinition.soText +
                //             "." +
                //             " But I can't because " + problemDefinition.becauseText +
                //             ".";
                //     }
                // }

                await conceptEditorService.saveConcept(context);
            }

            // const OnTabChange = (isFreeText: boolean) => {

            //     conceptEditorStore.sendMessageCustom("problemData.useFreeText", isFreeText);
            // }

            useEffect(() => {

                let currentValue = { severity: -1, affects: -1 };

                const hubContext = MessageHubContext()
                    .Subscribe(conceptEditorStore, (conceptModel: ConceptDataModel) => {
                        if (!conceptModel?.problemData) {
                            return;
                        }

                        //#calculation: problem-impact
                        const newValue = {
                            severity: conceptModel.problemData.conceptSeverity ?? ConceptComplexity.Low,
                            affects: conceptModel.problemData.conceptPeopleAffected ?? ConceptAffect.Few
                        }

                        if (!_.isEqual(currentValue, newValue)) {
                            currentValue = newValue;
                            conceptModel.conceptValue = new ConceptComplexityService(ConceptType.Problems).calculateConceptValue(newValue.severity, newValue.affects);
                        }
                    });


                return () => hubContext.Dispose();
            }, []);

            // useEffect(() => {
            //     const hubContext = MessageHubContext()
            //         .Subscribe(conceptEditorStore, (conceptModel: ConceptDataModel) => {

            //             hubContext.Dispose();
            //             if (conceptModel.problemData?.useFreeText) {
            //                 global.window.switchTab();
            //                 //global.window.triggerClick("freetext-tab");
            //                 //conceptEditorStore.sendMessageCustom("problemData.useFreeText", true);
            //                 //global.window.triggerClick("problem-freetext-tab");
            //             }
            //         });


            //     return () => hubContext.Dispose();
            // }, []);

            return (
                <React.Fragment>
                    <div className="modal-header bg-header text-white">
                        <SectionHeaderComponent iconName={context.conceptIcon} header={HelperExtension.GetConceptHeaderText(context.conceptType, isEditing)} iconSize={SectionHeaderIconSize.Medium} headerStyle="icon-bright" />
                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body bg-white vc-editor concept-editor ms-2 vc-custom-scroll">
                        <div className="mb-3 pt-3">
                            <SectionHeaderComponent iconName="#problem-icon" header="What's the problem? *" iconSize={SectionHeaderIconSize.Medium} />
                            <div className="mt-2">
                                <TextBoxComponent fieldName="title" dataStore={conceptEditorStore} maxLength={300} autoFocus={true} autoFocusDelayed={true} />
                            </div>
                        </div>
                        <hr className="my-4 mx-auto" />
                        <div>
                            <SectionHeaderComponent iconName="#impact-icon" header="Problem impact" iconSize={SectionHeaderIconSize.Medium} />
                            <div className="mt-3">
                                <RangeSelectorComponent
                                    header="Severity of the problem"
                                    fieldName="problemData.conceptSeverity"
                                    conceptStore={conceptEditorStore}
                                    selectionList={[
                                        { value: ConceptComplexity.Low, description: "Low" },
                                        { value: ConceptComplexity.LowMedium, description: "Medium/Low", positionFix: "15px" },
                                        { value: ConceptComplexity.Medium, description: "Medium", positionFix: "0px" },
                                        { value: ConceptComplexity.MediumHigh, description: "Medium/High", positionFix: "-5px" },
                                        { value: ConceptComplexity.High, description: "High" },
                                    ]}

                                />
                            </div>
                            <div className="mt-4">
                                <RangeSelectorComponent
                                    header="Number of people this affects"
                                    fieldName="problemData.conceptPeopleAffected"
                                    conceptStore={conceptEditorStore}
                                    selectionList={[
                                        { value: ConceptAffect.Few, description: "Few" },
                                        { value: ConceptAffect.Some, description: "Some", positionFix: "-15px" },
                                        { value: ConceptAffect.Many, description: "Many", positionFix: "-20px" },
                                        { value: ConceptAffect.Most, description: "Most", positionFix: "-20px" },
                                        { value: ConceptAffect.All, description: "All" },
                                    ]}
                                />
                            </div>
                        </div>
                        <hr className="my-4 mx-auto" />
                        <div>
                            <SectionHeaderComponent iconName="#ray-icon" header="Create your problem statement" iconSize={SectionHeaderIconSize.Medium} />
                            <div className="mt-3">
                                <span className="fw-reg-700">A problem well stated is a problem half solved!</span>
                                <span> A problem statement helps guides solution generation, enhances creativity, and helps in the evaluation of potential solutions.</span>
                                <a className="concept-link" href="https://simplyvoyce.com/blog/how-to-craft-the-perfect-problem-statement" target="_blank"> View tips and & examples.</a>
                            </div>
                        </div>
                        {/* 
                        <ul className="pt-3 nav d-none">
                            <li className="tab-item" role="presentation">
                                <button className="tab-link fw-reg-500 active"
                                    id="structured-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#problem-structured-tab"
                                    type="button" role="tab"
                                    onClick={() => OnTabChange(false)}
                                    aria-controls="problem-structured-tab" aria-selected="true">Use structured format (recommended)</button>
                            </li>
                            <li className="tab-item">
                                <button className="tab-link fw-reg-500"
                                    id="freetext-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#problem-freetext-tab"
                                    type="button" role="tab"
                                    onClick={() => OnTabChange(true)}
                                    aria-controls="problem-freetext-tab" aria-selected="false">Use free text</button>

                            </li>
                        </ul> */}
                        {/* <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="problem-structured-tab" role="tabpanel" aria-labelledby="problem-structured-tab" tabIndex={0}> */}
                        <div>
                            <div className="pt-3">
                                <TextBoxGroupComponent
                                    label="When"
                                    labelDescription="(Context)"
                                    // example={{
                                    //     exampleDescription: "The 'What' - What's the thing you are doing?",
                                    //     exampleList: ["purchasing a new lamp", "buying a product on the website", "reviewing whether or not to sign up to a subscription"]
                                    // }}
                                    textBox={{
                                        fieldName: "problemData.whenText",
                                        dataStore: conceptEditorStore,
                                        maxLength: 1000,
                                        placeholder: "people booking a holiday are using a travel booking app"
                                    }} />
                            </div>
                            <div className="pt-3">
                                <TextBoxGroupComponent
                                    label="they want"
                                    labelDescription="(Goal)"
                                    // example={{
                                    //     exampleDescription: "The 'Want' - What's the thing you want to do but perhaps can't?",
                                    //     exampleList: ["compare the lumens of the lamps", "see the estimated shipping costs before creating an account", "sign up"]
                                    // }}
                                    textBox={{
                                        fieldName: "problemData.wantText",
                                        dataStore: conceptEditorStore,
                                        maxLength: 1000,
                                        placeholder: "the search filters to be easy to adjust and understand"
                                    }} />
                            </div>
                            <div className="pt-3">
                                <TextBoxGroupComponent
                                    label="So they can"
                                    labelDescription="(Value)"
                                    // example={{
                                    //     exampleDescription: "The 'Why' - Why are you wanting to do this?",
                                    //     exampleList: ["know if the light will be bright enough for my room", "not waste my time", "try the product"]
                                    // }}
                                    textBox={{
                                        fieldName: "problemData.soText",
                                        dataStore: conceptEditorStore,
                                        maxLength: 1000,
                                        placeholder: "find the best flights suited to their needs"
                                    }} />
                            </div>
                            <div className="pt-3">
                                <TextBoxGroupComponent
                                    label="But they can't because"
                                    // example={
                                    //     {
                                    //         exampleDescription: "What's the reason why it's not possible?",
                                    //         exampleList: ["lumens are missing from the product detail", "I'm forced to create an account before seeing shipping", "I can't find the sign up form!"]
                                    //     }}
                                    textBox={{
                                        fieldName: "problemData.becauseText",
                                        dataStore: conceptEditorStore,
                                        maxLength: 1000,
                                        placeholder: "the app's filter options are confusing and difficult to manipulate"
                                    }} />
                            </div>
                            <div className="pt-3 d-flex flex-column fw-small-400">
                                <div><span className="fw-small-700 me-1">'When'</span>sets the context; it states who and what they are doing when they experience the problem.</div>
                                <div className="pt-1"><span className="fw-small-700 me-1">'They want'</span>is goal or what they are trying to achieve.</div>
                                <div className="pt-1"><span className="fw-small-700 me-1">'So they can'</span>is the value, motivation or benefit that achieving the goal will bring - think of this as the 'why?'</div>
                                <div className="pt-1"><span className="fw-small-700 me-1">'But they can't because'</span>This is the barrier or obstacle that's preventing the goal being achieved.</div>
                            </div>
                            {/* <div className="pt-3">
                                <ProblemPreviewComponent conceptEditorStore={conceptEditorStore} />
                            </div> */}
                        </div>
                        {/* <div className="tab-pane fade" id="problem-freetext-tab" role="tabpanel" aria-labelledby="problem-freetext-tab" tabIndex={0}>
                                <div className="mt-3">
                                    <div className="fw-small-500">If possible, try structuring your problem like this and be specific as possible.</div>
                                    <div className="fw-small-400">People X (the 'who') are wanting/trying/encountering problem Y (the 'what'), so that the can achieve Z (the 'why')</div>
                                    <div className="mt-3">
                                        <TextBoxComponent fieldName="description" dataStore={conceptEditorStore} maxLength={300} />
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* {
                            (!isEditing) &&
                            <React.Fragment>
                                <hr className="my-4 mx-auto" />
                                <div className="my-3">
                                    <SectionHeaderComponent iconName="#edit-icon" header={"Organize this " + context.conceptTypeDescription.toLowerCase()} />
                                    <div className="my-2">
                                        <FolderSelectionComponent dataStore={conceptEditorStore} />
                                    </div>
                                    <TagComponent conceptTagStore={conceptEditorService.tagStore} disableDelete={true} />
                                </div>
                            </React.Fragment>
                        } */}

                        <hr className="my-4 mx-auto" />
                        <div className="mb-3">
                            <SectionHeaderComponent iconName="#insight-icon" header="We know this is a problem because..." iconSize={SectionHeaderIconSize.Medium} />
                            <div className="mt-2 fw-small-400 fw-secondary">Provide any evidence or insights that this is being encountered.</div>
                            <div className="mb-1 fw-small-400 fw-secondary">TIP:Attach some insights!</div>
                            <div className="mt-2">
                                <TextBoxComponent fieldName="problemData.problemCause" dataStore={conceptEditorStore} maxLength={300} />
                            </div>
                            <ConceptLinkSectionButtonOnly
                                conceptLinkStore={conceptEditorService.conceptLinkStore}
                                sourceConceptType={context.conceptType}
                                targetConceptType={ConceptType.Insights} isEditing={true} />
                        </div>
                        <hr className="my-4 mx-auto" />
                        <div className="my-3">
                            <SectionHeaderComponent iconName="#edit-icon" header="Problem details" />
                            <div className="mt-2">
                                <TextBoxRichTextComponent fieldName="problemData.problemDetails" dataStore={conceptEditorStore} maxLength={3500} />
                            </div>
                        </div>
                        <hr className="my-4 mx-auto" />
                        <div className="my-3">
                            <ConceptImageAttachmentComponent attachmentStore={conceptEditorService.conceptAttachmentStore} />
                        </div>
                        <hr className="my-4 mx-auto" />
                        <div className="my-3">
                            <ConceptAttachmentComponent attachmentStore={conceptEditorService.conceptAttachmentStore} />
                        </div>

                        <hr className="my-4 mx-auto" />
                        <ConceptLinkSection conceptLinkStore={conceptEditorService.conceptLinkStore} sourceConceptType={context.conceptType} targetConceptType={ConceptType.Solutions} isEditing={true} />
                    </div>
                    <div className="modal-footer d-flex justify-content-between">
                        <div className="mx-md-3 mx-1">
                            <SaveButtonComponent
                                validatorType={TempValidatorType.Problem}
                                notificationHub={conceptEditorStore}
                                disableAutoClose={true}
                                ButtonClick={SaveConcept} />
                        </div>
                        <div className="mx-md-3 mx-1">
                            <button type="button" className="btn btn-outline-primary btn-concept-outline btn-medium" data-bs-dismiss="modal">Cancel</button>
                        </div>
                        {/* <button type="button" className="btn btn-primary btn-concept" data-bs-dismiss="modal" onClick={this.SaveConcept} >Save</button> */}


                    </div>

                </React.Fragment>
            );
        }
    )