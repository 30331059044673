import { CustomModalComponent } from "./CustomModalComponent"
import { ModalComponent } from "./ModalComponent"

export const ModalWrapperComponent = () => {


    return (
        <div onFocus={(event) => {            
            event.preventDefault();
            event.stopPropagation();
            return false;
        }}>
            <ModalComponent></ModalComponent>
            <CustomModalComponent></CustomModalComponent>
        </div>
    )
}