import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { GlobalNotifications, PageType } from "../models/common/CommonEnums";
import { ApplicationSignOut } from "../services/AuthenticationService";
import { GlobalNotificationHub } from "../services/GlobalMessageHub";
import { GlobalStore } from "../services/stores/GlobalStore";
import { CommonElements } from "../models/CommonElements";
import { LogCollector } from "../services/logger/LogCollector";

interface SidebarProps {
    //currentPage: ConceptType
    pageType: PageType;
}

function SwitchTheme() {
    if (document.body.getAttribute("data-theme") != null) {
        LogCollector.LogMessage("SwitchTheme-Light");
        document.body.removeAttribute("data-theme");
    }
    else {
        LogCollector.LogMessage("SwitchTheme-Dark");
        document.body.setAttribute("data-theme", "dark");
    }
}

const MainSidebar: React.FunctionComponent<SidebarProps> = (props) => {

    var insightActive = props.pageType === PageType.Insights ? " main-sidebar-active" : "";
    var problemsActive = props.pageType === PageType.Problems ? " main-sidebar-active" : "";
    var solutionsActive = props.pageType === PageType.Solutions ? " main-sidebar-active" : "";

    var dashboardActive = props.pageType === PageType.Dashboard ? " main-sidebar-active" : "";

    const navigate = useNavigate();

    const NavigateTo = (layoutType: PageType) => {
        const wCode = GlobalStore.getWorkspaceCodeIfAvailable();

        switch (layoutType) {
            case PageType.Insights:
                navigate("/w/" + wCode + "/insights/");
                break;
            case PageType.Problems:
                navigate("/w/" + wCode + "/problems/");
                break;
            case PageType.Solutions:
                navigate("/w/" + wCode + "/solutions/");
                break;
            case PageType.Dashboard:
                navigate("/w/" + wCode + "/");
                break;
        }
    }

    return (
        <React.Fragment>
            <div className="offcanvas offcanvas-start bg-voyce-dark text-white" tabIndex={-1} id="offcanvasScrolling" aria-labelledby="offcanvasScrolling">
                <div className="offcanvas-header">
                    <Link to="/" className="d-flex align-items-center nav-link">
                        <svg className="pe-none white-icon" width="46" height="30" role="img" aria-label="Home" ><use xlinkHref="#voyce-logo-white"></use></svg>
                        <svg className="pe-none white-icon ms-1" width="49" height="12" role="img" aria-label="Home"><use xlinkHref="#voyce-word-white"></use></svg>
                    </Link>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="d-flex flex-column flex-shrink-0 text-center main-sidebar h-100">
                        <ul className="nav nav-pills nav-flush flex-column mb-auto text-center pt-3">
                            <li className={"nav-item" + dashboardActive}>
                                <div id={CommonElements.DashboardTab} data-bs-dismiss="offcanvas" className="nav-link py-3 concept-link" role="button" onClick={() => NavigateTo(PageType.Dashboard)}>
                                    <svg className="pe-none w-100" width="36" height="34" role="img" aria-label="Insights"><use xlinkHref="#dashboard-icon"></use></svg>
                                    <div className="fw-small-400 mt-1">Dashboard</div>
                                </div>
                            </li>
                            <li className={"nav-item" + insightActive}>
                                <div className="nav-link py-3 concept-link" data-bs-dismiss="offcanvas" role="button" onClick={() => NavigateTo(PageType.Insights)}>
                                    <svg className="pe-none w-100" width="36" height="37" role="img" aria-label="Insights"><use xlinkHref="#insight-icon"></use></svg>
                                    <div className="fw-small-400 mt-1">Insights</div>
                                </div>
                            </li>
                            <li className={"nav-item" + problemsActive}>
                                <div className="nav-link py-3 concept-link" data-bs-dismiss="offcanvas" aria-label="Problems" role="button" onClick={() => NavigateTo(PageType.Problems)}>
                                    <svg className="pe-none w-100" width="36" height="37" role="img" aria-label="Problems"><use xlinkHref="#problem-icon"></use></svg>
                                    <div className="fw-small-400 mt-1">Problems</div>
                                </div>
                            </li>
                            <li className={"nav-item" + solutionsActive}>
                                <div className="nav-link py-3 concept-link" data-bs-dismiss="offcanvas" role="button" onClick={() => NavigateTo(PageType.Solutions)}>
                                    <svg className="pe-none w-100" width="46" height="37" role="img" aria-label="Solutions"><use xlinkHref="#solution-icon"></use></svg>
                                    <div className="fw-small-400 mt-1">Solutions</div>
                                </div>
                            </li>
                        </ul>
                        <div className="dropdown pb-3">
                            <ApplicationSignOut />
                        </div>
                    </div>
                </div>
            </div>

            <aside className="layout-main-sidebar d-sidebar">
                <div className="d-flex flex-nowrap h-100">
                    <div className="d-flex flex-column flex-shrink-0 bg-white text-center main-sidebar" style={{ width: "120px" }}>
                        <Link to="/" className="d-block p-3 voyce-logo" style={{ height: "5.5em" }}>
                            <svg className="pe-none" width="56" height="46" role="img" aria-label="Home"><use xlinkHref="#voyce-logo"></use></svg>
                            <svg className="pe-none" width="49" height="12" role="img" aria-label="Home"><use xlinkHref="#voyce-word"></use></svg>
                        </Link>
                        <hr className="my-0 mx-auto w-75" />
                        <ul className="nav nav-pills nav-flush flex-column mb-auto text-center pt-3">
                            <li className={"nav-item" + dashboardActive}>
                                <div id={CommonElements.DashboardTab} className="nav-link py-3 concept-link" role="button" onClick={() => NavigateTo(PageType.Dashboard)}>
                                    <svg className="pe-none w-100" width="36" height="34" role="img" aria-label="Insights"><use xlinkHref="#dashboard-icon"></use></svg>
                                    <div className="fw-small-400 mt-1">Dashboard</div>
                                </div>
                            </li>
                            <li className={"nav-item" + insightActive}>
                                <div id={CommonElements.InsightTab} className="nav-link py-3 concept-link" role="button" onClick={() => NavigateTo(PageType.Insights)}>
                                    <svg className="pe-none w-100" width="36" height="34" role="img" aria-label="Insights"><use xlinkHref="#insight-icon"></use></svg>
                                    <div className="fw-small-400 mt-1">Insights</div>
                                </div>
                            </li>
                            <li className={"nav-item" + problemsActive}>
                                <div id={CommonElements.ProblemTab} className="nav-link py-3 concept-link" role="button" onClick={() => NavigateTo(PageType.Problems)}>
                                    <svg className="pe-none w-100" width="36" height="37" role="img" aria-label="Problems"><use xlinkHref="#problem-icon"></use></svg>
                                    <div className="fw-small-400 mt-1">Problems</div>
                                </div>
                            </li>
                            <li className={"nav-item" + solutionsActive}>
                                <div id={CommonElements.SolutionTab} className="nav-link py-3 concept-link" role="button" onClick={() => NavigateTo(PageType.Solutions)}>
                                    <svg className="pe-none w-100" width="46" height="37" role="img" aria-label="Solutions"><use xlinkHref="#solution-icon"></use></svg>
                                    <div className="fw-small-400 mt-1">Solutions</div>
                                </div>
                            </li>
                            {/* <li className="nav-item">
                                <Link to="/invalid/" className="nav-link py-3 link-dark" aria-label="Solutions">
                                    Invalid link
                                </Link>
                            </li> */}
                        </ul>
                        {/* <UserDetailsComponent /> */}
                        <div className="dropdown pb-3">
                            {/* <li><a className="dropdown-item" href="/#">New project...</a></li>
                                <li><a className="dropdown-item" href="/#">Settings</a></li>
                                <li><a className="dropdown-item" href="/#">Profile</a></li> */}
                            {/* <li><button className="dropdown-item" onClick={SwitchTheme}>Switch Theme</button></li>
                                <li><hr className="dropdown-divider" /></li> */}

                            <ApplicationSignOut />
                        </div>
                    </div>
                </div>
            </aside>
        </React.Fragment>
    );
}

export default MainSidebar;