import { ModalComponent } from "../../components/modal/ModalComponent"
import { HelpUsComponent } from "../../components/notification/HelpUsComponent"
import TeamMembersImage from "../../../assets/images/app/team-members.svg";
import { CommonElements } from "../../models/CommonElements";
import { WorkspaceTeamStore, WorkspaceTeamStoreElement, WorkspaceTeamStoreState } from "../../services/stores/Workspace/WorkspaceTeamStore";
import { useCallback, useState } from "react";
import { MessageHubHandler } from "../../services/notification/MessageHubHandler";
import { WorkspaceMemberDataModel } from "../../models/data/WorkspaceMemberDataModel";
import { WorkspaceTeamMemberComponent } from "../../components/workspace/WorkspaceTeamMemberComponent";
import { AlertTimerComponent } from "../../components/notification/AlertTimerComponent";
import { GlobalNotificationHub } from "../../services/GlobalMessageHub";
import { GlobalNotifications } from "../../models/common/CommonEnums";

export const WorkspaceTeamPage = () => {

    const [store, setStore] = useState<WorkspaceTeamStore>();
    const [workspaceTeamList, setWorkspaceTeamList] = useState<WorkspaceMemberDataModel[]>([]);

    const onTeamStoreLoaded = useCallback((store: WorkspaceTeamStore, hubContext: MessageHubHandler) => {

        hubContext.Subscribe(store, (workspaceTeam: WorkspaceTeamStoreState) => {
            setStore(store);
            setWorkspaceTeamList(workspaceTeam.members);
        });

    }, []);

    const OnRemoveMember = useCallback(async (workspaceMember: WorkspaceMemberDataModel) => {
        if (!store) {
            return;
        }

        await store.removeMember(workspaceMember);
    }, [store]);

    return (
        <div className="d-flex flex-nowrap">
            <main className="container-fluid p-0">
                <div className="layout-main-content d-flex justify-content-center pt-5">
                    <div className="d-flex flex-column align-items-center wd-region">
                        <div className="wd d-flex flex-column align-items-center">
                            <div style={{ height: "280px" }}>
                                <img src={TeamMembersImage} alt="Workspace team" style={{ maxWidth: "100%", height: "280px" }} />
                            </div>
                            <div className="workspace-add-region insight-icon fw-reg-500 fw-concept text-center ms-2 my-3 p-2 w-100" role="button"
                                data-bs-toggle="modal" data-bs-target={CommonElements.ModalElementTarget} onClick={() => {
                                    GlobalNotificationHub.sendMessage(GlobalNotifications.WorkspaceInvitation);
                                }}>
                                <svg className="pe-none" width="17" height="16" role="img" aria-label="NewTeamMember"><use xlinkHref="#add-concept-icon"></use></svg>
                                <span className="ps-2 align-middle">Invite team member</span>
                            </div>
                            <div className="w-100 ps-1 pt-3">
                                {
                                    workspaceTeamList.map(workspaceTeam => {

                                        return (
                                            <WorkspaceTeamMemberComponent key={workspaceTeam.teamMemberID} workspaceTeam={workspaceTeam} onMemberDelete={OnRemoveMember} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </main >

            <WorkspaceTeamStoreElement workspaceTeamStoreLoad={onTeamStoreLoaded} />
            {/* <WorkspaceEditor workspaceStore={this.workspaceStore as WorkspaceStore} /> */}
            <AlertTimerComponent />
            <ModalComponent />
            <HelpUsComponent />
            {/* <MobileComponentDetector /> */}
        </div >
    )
}