import { ConceptType, ConceptTypeEnumLabel } from "../models/common/CommonEnums";
import { ProblemDefinitionModel } from "../models/concept/ProblemDefinitionModel";
import { ConceptDataModel } from "../models/ConceptDataModel";
import { FolderDataModel, FolderType } from "../models/data/FolderDataModel";
import { GlobalStore } from "./stores/GlobalStore";

// export const TruncateText = (textValue: string, maxLenght: number) => {

//     if (!textValue) {
//         return "";
//     }


//     return textValue.length > maxLenght ? textValue.substring(0, (maxLenght - 3)) + "..." : textValue;
// }


export const HelperExtension = {

    IsMobile() {
        return window.innerWidth <= 768;
    },

    GetSystemFolderDescription(currentFolder: FolderDataModel, conceptDescription: string | undefined) {

        if (!currentFolder || !conceptDescription) {
            return "";
        }

        switch (currentFolder.folderType) {
            case FolderType.SystemAllConcepts:
                return "All " + conceptDescription + "s";
            case FolderType.SystemNotSorted:
                return "Not in a folder";
            case FolderType.SystemQikFeedbackForm:
                return "Voyce feedback";
        }

        return currentFolder.folderName;
    },

    GetConceptHeaderText(conceptType: ConceptType, isEditing: boolean) {

        if (isEditing) {
            return ConceptTypeEnumLabel.get(conceptType) as string;
        }

        switch (conceptType) {
            case ConceptType.Problems:
                return "Add a new problem";
            case ConceptType.Solutions:
                return "Add a new solution";
            default:
                return "Add a new insight";
        }
    },
    GetAddConceptText(conceptType: ConceptType) {

        switch (conceptType) {
            case ConceptType.Problems:
                return "Add a new problem";
            case ConceptType.Solutions:
                return "Add a new solution";
            default:
                return "Add a new insight";
        }
    },

    GetConceptName(conceptType: ConceptType, numberOfConcepts: number) {
        return ConceptTypeEnumLabel.get(conceptType) + (numberOfConcepts !== 1 ? "s" : "");
    },

    GetTeamSize(teamSize: number) {
        if (!teamSize) {
            return "1 Team member";
        }

        if (teamSize > 1) {
            return String(teamSize) + " Team members"
        }

        return String(teamSize) + " Team member";
    },

    GetFeedbackName() {
        return "Voyce Feedback";
    },


    GetConceptNameSingular(conceptType: ConceptType) {
        return ConceptTypeEnumLabel.get(conceptType);
    },
    GetConceptNamePlural(conceptType: ConceptType) {
        return ConceptTypeEnumLabel.get(conceptType) + "s";
    },

    GetConceptSuccessMessage(conceptType: ConceptType) {
        if (!conceptType) {
            return undefined;
        }

        return ConceptTypeEnumLabel.get(conceptType) + " saved";
    },
    GetConceptDeleteMessage(conceptType: ConceptType) {
        if (!conceptType) {
            return undefined;
        }

        return ConceptTypeEnumLabel.get(conceptType) + " deleted";
    },
    GetCommentDeleteMessage() {
        return "Comment deleted";
    },

    GetConceptIcon(conceptType: ConceptType) {
        switch (conceptType) {
            case ConceptType.Problems:
                return "#problem-icon";
            case ConceptType.Solutions:
                return "#solution-icon";
            default:
                return "#insight-icon";
        }
    },

    GetPropertyValue(fieldPath: string, mainObject: any) {

        const fieldNames = fieldPath.split(".");
        let value: string | undefined;

        if (fieldNames.length === 1) {
            value = mainObject[fieldPath];
        }
        else {
            const parent = fieldNames[0];
            const field = fieldNames[1];

            const parentObject = mainObject[parent];
            if (parentObject) {
                value = parentObject[field];
            }
        }

        if (!value) {
            return "";
        }

        return String(value);
    },

    GetPropertyValueObject(fieldPath: string, mainObject: any) {

        const fieldNames = fieldPath.split(".");
        let value: any;

        if (fieldNames.length === 1) {
            value = mainObject[fieldPath];
        }
        else {
            const parent = fieldNames[0];
            const field = fieldNames[1];

            const parentObject = mainObject[parent];
            if (parentObject) {
                value = parentObject[field];
            }
        }

        return value;
    },

    SetPropertyValueObject(currentValue: any, fieldPath: string, mainObject: any) {

        const fieldNames = fieldPath.split(".");

        if (fieldNames.length === 1) {
            // Updates the temporary storage
            return { ...currentValue, [fieldPath]: mainObject };
        }
        else {
            // Updates the temporary storage
            const parent = fieldNames[0];
            const field = fieldNames[1];

            currentValue = {
                ...currentValue,
                [parent]: {
                    ...(currentValue as any)[parent],
                    [field]: mainObject
                }
            };

            return currentValue;
        }
    },

    ProcessConceptPartialUpdate(conceptData: ConceptDataModel, conceptPartialData: ConceptDataModel) {

        return {
            ...conceptData, ...conceptPartialData,
            solutionData: { ...conceptData.solutionData, ...conceptPartialData.solutionData }
        } as ConceptDataModel;

    },

    GetProblemDefinition(conceptData: ConceptDataModel) {
        return ((conceptData?.description) ? JSON.parse(conceptData.description) : {}) as ProblemDefinitionModel;
    },

    GetConceptUrl(conceptData: ConceptDataModel) {
        const workspaceCode = GlobalStore.getWorkspaceCodeIfAvailable();

        switch (conceptData.conceptType) {
            case ConceptType.Solutions:
                return `/w/${workspaceCode}/solutions/${conceptData.conceptID}/`
            case ConceptType.Problems:
                return `/w/${workspaceCode}/problems/${conceptData.conceptID}/`
            default:
                return `/w/${workspaceCode}/insights/${conceptData.conceptID}/`
        }
    }
}