import { ConceptType, ConceptTypeEnumLabel } from "../../models/common/CommonEnums";
import { AppContextData, VoyceContextProviderProps } from "./AppContext";

export const VoyceContextExtension = (props: VoyceContextProviderProps) => {

    const GetConceptIcon = () => {
        switch (props.conceptType) {
            case ConceptType.Problems:
                return "#problem-icon";
            case ConceptType.Solutions:
                return "#solution-icon";
            default:
                return "#insight-icon";
        }
    }

    const applicationContext = {
        conceptType: props.conceptType,
        conceptTypeDescription: ConceptTypeEnumLabel.get(props.conceptType),

        conceptIcon: GetConceptIcon(),

        state: { selectedFolder: { folderID: "" } }
    } as AppContextData;

    return applicationContext;
}