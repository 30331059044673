import { ConceptLinkExistingNotificationModel } from "../../../models/communication/internal/ConceptLinkExistingNotificationModel";
import { ConceptLinkContentComponentV2 } from "../../conceptlink/ConceptLinkContentComponent";
import { ModalDefinitionModel, ModalSize } from "../ModalComponent";

export const ConceptLinkExistingHandler = (notification: ConceptLinkExistingNotificationModel, onCloseModal: () => void) => {

    return {
        modalSize: ModalSize.Xlarge,
        modalContent: (
            <ConceptLinkContentComponentV2
                conceptType={notification.targetConceptType}
                conceptLinkStore={notification.conceptLinkStore}
                onCloseModal={onCloseModal} />
        )
    } as ModalDefinitionModel;
}