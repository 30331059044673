import React, { PropsWithChildren, useEffect } from "react";
import { ConceptType, GlobalNotifications } from "../../models/common/CommonEnums";
import { FolderDataModel } from "../../models/data/FolderDataModel";
import { GlobalNotification } from "../GlobalMessageHub";
import { MessageHubContext } from "../notification/MessageHubHandler";
import { VoyceContextExtension } from "./VoyceContextExtension";


export interface AppStateData {

    selectedFolder: FolderDataModel;
}


export interface AppContextData {
    conceptType: ConceptType;
    conceptTypeDescription: string;

    conceptIcon: string;
    state: AppStateData;
}

const defaultContextValue = {

    state: {}

} as AppContextData;

export interface VoyceContextProviderProps {
    conceptType: ConceptType;
}

const ApplicationContext = React.createContext(defaultContextValue);
ApplicationContext.displayName = "VoyceContext";


export const VoyceContextProvider = (props: PropsWithChildren<VoyceContextProviderProps>) => {

    useEffect(() => {
        const hubContext = MessageHubContext()
            .ListenGlobalNotification([GlobalNotifications.FolderSelectChange], (notification: GlobalNotification) => {
                applicationContext.state.selectedFolder = (notification.notificationData as FolderDataModel);
            });

        return () => hubContext.Dispose();
    })

    const applicationContext = VoyceContextExtension(props);

    return (
        <ApplicationContext.Provider value={applicationContext}>
            {props.children}
        </ApplicationContext.Provider>
    )
}

export const useAppContext = (): AppContextData => {
    const context = React.useContext(ApplicationContext);

    if (!context) {
        throw new Error("VoyceContextProvider context is undefined, please verify you are calling useAppContext() as child of a <VoyceContextProvider> component.");
    }

    return context;
};
