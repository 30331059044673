import React from "react"
import { memo } from "react"
import { ConceptType, GlobalNotifications } from "../../../models/common/CommonEnums"
import { ConceptEditNotificationModel } from "../../../models/communication/internal/ConceptEditNotificationModel"
import { LinkToNewConceptNotificationModel } from "../../../models/communication/internal/LinkToNewConceptNotificationModel"
import { ConceptEditorService } from "../../../services/concepts/ConceptEditorService"
import { ConceptSearchService } from "../../../services/concepts/ConceptSearchService"
import { useAppContext } from "../../../services/context/AppContext"
import { GlobalNotificationHub } from "../../../services/GlobalMessageHub"
import { HelperExtension } from "../../../services/HelperExtension"
import { CommentComponent } from "../../comments/CommentComponent"
import { AreYouSureComponent } from "../../notification/AreYouSureComponent"
import { InsightViewerComponent } from "../insights/InsightViewerComponent"
import { ProblemViewerComponent } from "../problems/ProblemViewerComponent"
import { SolutionViewerComponent } from "../solutions/SolutionViewerComponent"
import { ConceptImpactComponent } from "./ConceptImpactComponent"
import { CommonElements } from "../../../models/CommonElements"
import { TextDisplayComponent } from "../../elements/TextDisplayComponent"
import { ConceptShareComponent } from "./ConceptShareComponent"
import { ConceptLinkExistingNotificationModel } from "../../../models/communication/internal/ConceptLinkExistingNotificationModel"

interface ConceptViewerContentProps {
    conceptID: string;
    conceptService: ConceptEditorService;

    isFullscreen?: boolean;
}

export const ConceptViewerContent: React.FunctionComponent<ConceptViewerContentProps>
    = memo(function ConceptViewerContentImplementation({ conceptID, conceptService, isFullscreen }) {

        const context = useAppContext();

        const dataToggle = isFullscreen ? "modal" : undefined;
        const dataToggleTarget = isFullscreen ? CommonElements.ModalElementTarget : undefined;

        const OnConceptLinkToNew = (targetConcept: ConceptType) => {
            GlobalNotificationHub.sendMessageWithData(GlobalNotifications.LinkToNewConcept,
                {
                    linkToConcept: conceptService?.conceptEditorStore.storageState,
                    targetConceptType: targetConcept,
                    closeOnComplete: (isFullscreen ?? false)
                } as LinkToNewConceptNotificationModel);
        }

        const OnConceptEdit = () => {
            GlobalNotificationHub.sendMessageWithData(GlobalNotifications.EditConcept, { conceptID: conceptID, closeOnComplete: (isFullscreen ?? false) } as ConceptEditNotificationModel);
        }

        const OnConceptDeleteConfirm = async () => {
            if (!conceptService) {
                return;
            }

            await conceptService.deleteConcept(conceptID);

            if (isFullscreen) {
                window.close();
            }
        }

        const RenderButtonLinks = () => {

            if (context.conceptType === ConceptType.Solutions || !conceptService) {
                return (<React.Fragment></React.Fragment>);
            }

            const targetConceptType = context.conceptType === ConceptType.Insights
                ? ConceptType.Problems
                : ConceptType.Solutions;

            const targetConceptDescription = HelperExtension.GetConceptNameSingular(targetConceptType)?.toLowerCase();
            const conceptLinkStore = conceptService.conceptLinkStore; //new ConceptLinkStore();
            //ConceptSearchService.searchConcept(targetConceptType, "", "");

            const dropdownUID = crypto.randomUUID();

            const OnLinkConcepts = async () => {
                await ConceptSearchService.searchConcept(targetConceptType, "", "");
                GlobalNotificationHub.sendMessageWithData(GlobalNotifications.LinkConcept, { targetConceptType: targetConceptType, conceptLinkStore: conceptLinkStore } as ConceptLinkExistingNotificationModel);
            }

            return (
                <React.Fragment>
                    <div>
                        <button className="btn btn-primary btn-concept btn-mod-dark w-100" data-bs-toggle={dataToggle} data-bs-target={dataToggleTarget}
                            onClick={() => OnConceptLinkToNew(targetConceptType)}>Define a {targetConceptDescription} for this</button>
                    </div>
                    <div className="mt-2">
                        <button id={dropdownUID} className="btn btn-primary btn-concept btn-mod-dark w-100"
                            // data-bs-toggle="dropdown"
                            // data-bs-auto-close="outside"
                            // aria-expanded="false"
                            onClick={OnLinkConcepts}
                        >Link to existing {targetConceptDescription}</button>

                        {/* <ConceptLinkContentComponent conceptType={targetConceptType} conceptLinkStore={conceptLinkStore} dropdownAligment="dropdown-menu-end" dropdownUID={dropdownUID} /> */}

                    </div>
                </React.Fragment>
            )
        }


        return (
            <React.Fragment>
                <div>
                    <div className="p-4 bg-white">
                        <div className="fw-xlarge-700 fw-dark text-break">
                            <TextDisplayComponent fieldName="title" dataStore={conceptService.conceptEditorStore} />
                        </div>
                        <div className="pt-1 d-flex fw-xsmall-400">
                            <div className="me-1">Added</div>
                            <TextDisplayComponent fieldName="creationDateDisplay" dataStore={conceptService.conceptEditorStore} />
                        </div>
                        <hr className="mx-auto" />
                        {/* <div className="pt-3">
                            <FolderSelectionComponent dataStore={dataStore} />
                        </div>
                        <div className="pt-2">
                            <TagComponent tagStore={conceptService.tagStore} />
                        </div>
                        */}
                        {
                            (context.conceptType === ConceptType.Insights) &&
                            <InsightViewerComponent conceptService={conceptService} />
                        }
                        {
                            (context.conceptType === ConceptType.Problems) &&
                            <ProblemViewerComponent conceptService={conceptService} />
                        }
                        {
                            (context.conceptType === ConceptType.Solutions) &&
                            <SolutionViewerComponent conceptService={conceptService} />
                        }
                    </div>
                </div>
                <div className="d-none d-md-block">
                    <div className="concept-bg-light w-100 h-100">
                        <div className="d-flex flex-column p-3 g-3 h-100">
                            {
                                (context.conceptType !== ConceptType.Insights) &&
                                <div>
                                    <ConceptImpactComponent conceptStore={conceptService.conceptEditorStore} />
                                </div>
                            }
                            <div className="pt-3">
                                <RenderButtonLinks />
                            </div>
                            <div className="mt-2">
                                {/* <button className="btn btn-primary btn-concept-outline btn-mod w-100">Share</button> */}
                                <ConceptShareComponent conceptID={conceptID} conceptType={context.conceptType} />
                            </div>
                            <div className="mt-2">
                                <button id={CommonElements.EditConcept}
                                    className="btn btn-primary btn-concept-outline btn-mod w-100"
                                    data-bs-toggle={dataToggle} data-bs-target={dataToggleTarget} onClick={OnConceptEdit}>Edit</button>
                            </div>
                            <div className="mt-2">
                                <AreYouSureComponent deleteElementID={CommonElements.DeleteConcept}
                                    OnConfirmAction={OnConceptDeleteConfirm}
                                />
                            </div>
                        </div>
                    </div>
                </div>


                <div>
                    <div className="p-2">
                        {/* Comment section */}
                        <CommentComponent conceptID={conceptID} />
                    </div>
                </div>
                <div className="d-none d-md-block concept-bg-light w-100 h-100"></div>

            </React.Fragment>
        )
    })