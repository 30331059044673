import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection, $isRangeSelection, CAN_REDO_COMMAND, CAN_UNDO_COMMAND, REDO_COMMAND, SELECTION_CHANGE_COMMAND, UNDO_COMMAND, $isRootOrShadowRoot } from "lexical";
import { useCallback, useEffect, useRef, useState } from "react";

import { $isListNode, ListNode } from "@lexical/list";
import { $isHeadingNode } from "@lexical/rich-text";
import { $isLinkNode } from "@lexical/link";

import { $findMatchingParent, $getNearestNodeOfType, mergeRegister, } from '@lexical/utils';

import { ToolbarPlugginHelpers } from "./ToolbarPlugginHelpers";
import { ToolbarItemDivider } from "./ToolbarItemDivider";
import { ToolbarItemComponent } from "./ToolbarItemComponent";
import { ToolbarListComponent } from "./ToolbarListComponent";
import { ToolbarLinkComponent } from "./ToolbarLinkComponent";
import { ToolbarCustomComponent } from "./ToolbarCustomComponent";

export const ToolbarPluginV2 = () => {

    const [editor] = useLexicalComposerContext();
    const toolbarRef = useRef<HTMLDivElement>(null);

    const [selectedElementKey, setSelectedElementKey] = useState<string>();
    const [blockType, setBlockType] = useState("paragraph");

    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);

    //const [codeLanguage, setCodeLanguage] = useState("");
    //const [isRTL, setIsRTL] = useState(false);
    const [isLink, setIsLink] = useState(false);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    //const [isCode, setIsCode] = useState(false);

    const LowPriority = 1;

    // const updateToolbar = useCallback(() => {
    //     const selection = $getSelection();
    //     if ($isRangeSelection(selection)) {
    //         const anchorNode = selection.anchor.getNode();
    //         const element =
    //             anchorNode.getKey() === "root"
    //                 ? anchorNode
    //                 : anchorNode.getTopLevelElementOrThrow();
    //         const elementKey = element.getKey();
    //         const elementDOM = editor.getElementByKey(elementKey);
    //         if (elementDOM !== null) {
    //             setSelectedElementKey(elementKey);
    //             if ($isListNode(element)) {
    //                 const parentList = $getNearestNodeOfType(anchorNode, ListNode);
    //                 const type = parentList ? parentList.getTag() : element.getTag();
    //                 setBlockType(type);
    //             } else {
    //                 const type = $isHeadingNode(element)
    //                     ? element.getTag()
    //                     : element.getType();
    //                 setBlockType(type);
    //                 if ($isCodeNode(element)) {
    //                     setCodeLanguage(element.getLanguage() || getDefaultCodeLanguage());
    //                 }
    //             }
    //         }
    //         // Update text format
    //         setIsBold(selection.hasFormat("bold"));
    //         setIsItalic(selection.hasFormat("italic"));
    //         setIsUnderline(selection.hasFormat("underline"));
    //         setIsStrikethrough(selection.hasFormat("strikethrough"));
    //         setIsCode(selection.hasFormat("code"));
    //         //setIsRTL($isParentElementRTL(selection));

    //         // Update links
    //         const node = ToolbarPlugginHelpers.getSelectedNode(selection);
    //         const parent = node.getParent();
    //         if ($isLinkNode(parent) || $isLinkNode(node)) {
    //             setIsLink(true);
    //         } else {
    //             setIsLink(false);
    //         }
    //     }
    // }, [editor]);
    const blockTypeToBlockName = {
        bullet: 'Bulleted List',
        check: 'Check List',
        code: 'Code Block',
        h1: 'Heading 1',
        h2: 'Heading 2',
        h3: 'Heading 3',
        h4: 'Heading 4',
        h5: 'Heading 5',
        h6: 'Heading 6',
        number: 'Numbered List',
        paragraph: 'Normal',
        quote: 'Quote',
    };


    const updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            let element =
                anchorNode.getKey() === 'root'
                    ? anchorNode
                    : $findMatchingParent(anchorNode, (e) => {
                        const parent = e.getParent();
                        return parent !== null && $isRootOrShadowRoot(parent);
                    });

            if (element === null) {
                element = anchorNode.getTopLevelElementOrThrow();
            }

            const elementKey = element.getKey();
            const elementDOM = editor.getElementByKey(elementKey);

            // Update text format
            setIsBold(selection.hasFormat("bold"));
            setIsItalic(selection.hasFormat("italic"));
            setIsUnderline(selection.hasFormat("underline"));
            setIsStrikethrough(selection.hasFormat("strikethrough"));
            //setIsSubscript(selection.hasFormat('subscript'));
            //setIsSuperscript(selection.hasFormat('superscript'));
            //setIsCode(selection.hasFormat("code"));
            //setIsRTL($isParentElementRTL(selection));

            // Update links
            const node = ToolbarPlugginHelpers.getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            } else {
                setIsLink(false);
            }

            // const tableNode = $findMatchingParent(node, $isTableNode);
            // if ($isTableNode(tableNode)) {
            //     setRootType('table');
            // } else {
            //     setRootType('root');
            // }

            if (elementDOM !== null) {
                setSelectedElementKey(elementKey);
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType<ListNode>(
                        anchorNode,
                        ListNode,
                    );
                    const type = parentList
                        ? parentList.getListType()
                        : element.getListType();
                    setBlockType(type);
                } else {
                    const type = $isHeadingNode(element)
                        ? element.getTag()
                        : element.getType();
                    if (type in blockTypeToBlockName) {
                        setBlockType(type as keyof typeof blockTypeToBlockName);
                    }
                    // if ($isCodeNode(element)) {
                    //     const language =
                    //         element.getLanguage() as keyof typeof CODE_LANGUAGE_MAP;
                    //     setCodeLanguage(
                    //         language ? CODE_LANGUAGE_MAP[language] || language : '',
                    //     );
                    //     return;
                    // }
                }
            }
            // Handle buttons
            // setFontSize(
            //     $getSelectionStyleValueForProperty(selection, 'font-size', '15px'),
            // );
            // setFontColor(
            //     $getSelectionStyleValueForProperty(selection, 'color', '#000'),
            // );
            // setBgColor(
            //     $getSelectionStyleValueForProperty(
            //         selection,
            //         'background-color',
            //         '#fff',
            //     ),
            // );
            // setFontFamily(
            //     $getSelectionStyleValueForProperty(selection, 'font-family', 'Arial'),
            // );
            // setElementFormat(
            //     ($isElementNode(node)
            //         ? node.getFormatType()
            //         : parent?.getFormatType()) || 'left',
            // );
        }
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateToolbar();
                });
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_payload, newEditor) => {
                    updateToolbar();
                    return false;
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_UNDO_COMMAND,
                (payload) => {
                    setCanUndo(payload);
                    return false;
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_REDO_COMMAND,
                (payload) => {
                    setCanRedo(payload);
                    return false;
                },
                LowPriority
            )
        );
    }, [editor, updateToolbar]);

    return (
        <div className="toolbar d-flex flex-wrap" ref={toolbarRef}>

            <ToolbarItemComponent isActive={isBold} commandText="bold" />
            <ToolbarItemComponent isActive={isItalic} commandText="italic" />
            <ToolbarItemComponent isActive={isUnderline} commandText="underline" />
            <ToolbarItemComponent isActive={isStrikethrough} commandText="strikethrough" />

            <ToolbarItemDivider />
            <ToolbarLinkComponent isActive={isLink} setIsLink={(isLink: boolean) => setIsLink(isLink)} />

            <ToolbarItemDivider />
            <ToolbarListComponent blockType={blockType} itemKey="bullet" itemStyle="bullet-list" />
            <ToolbarListComponent blockType={blockType} itemKey="number" itemStyle="numeric-list" />
            <ToolbarListComponent blockType={blockType} itemKey="check" itemStyle="check-list" />

            <ToolbarItemDivider />
            <ToolbarCustomComponent blockType={blockType} desiredBlockType="quote" />
            <ToolbarCustomComponent blockType={blockType} desiredBlockType="code" />

            <div className="flex-fill"></div>


            <button tabIndex={-1} disabled={!canUndo} className="toolbar-item spaced" aria-label="Undo"
                onClick={() => {
                    editor.dispatchCommand(UNDO_COMMAND, undefined);
                }}>
                <i className="format undo" />
            </button>
            <button tabIndex={-1} disabled={!canRedo} className="toolbar-item" aria-label="Redo"
                onClick={() => {
                    editor.dispatchCommand(REDO_COMMAND, undefined);
                }}>
                <i className="format redo" />
            </button>


            {/* <button className={"toolbar-item spaced " + (isBold ? "active" : "")} aria-label="Format Bold"
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
                }}>
                <i className="format bold" />
            </button>
            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
                }}
                className={"toolbar-item spaced " + (isItalic ? "active" : "")}
                aria-label="Format Italics"
            >
                <i className="format italic" />
            </button>
            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
                }}
                className={"toolbar-item spaced " + (isUnderline ? "active" : "")}
                aria-label="Format Underline"
            >
                <i className="format underline" />
            </button>
            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
                }}
                className={
                    "toolbar-item spaced " + (isStrikethrough ? "active" : "")
                }
                aria-label="Format Strikethrough"
            >
                <i className="format strikethrough" />
            </button> */}
        </div>
    )
}

